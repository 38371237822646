import { RestApiResponse } from '../../common/RestApiResponse';
import { BaseField } from '../BaseField';

export class TaxonomyBaseFieldUpdateResponse extends RestApiResponse {
    public field: BaseField;

    constructor(data?: Partial<TaxonomyBaseFieldUpdateResponse>) {
        super(data);
        this.field = data?.field;
    }
}
