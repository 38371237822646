import { IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../crud/CrudItemListRequest';

export class DealerListRequest extends CrudItemListRequest {
    @IsOptional()
    @IsString()
    public readonly search?: number | string;

    constructor(data?: Partial<DealerListRequest>) {
        super(data);
        this.search = data && data.search;
    }
}
