import { IsNotEmpty, IsNumber } from 'class-validator';

export class NomenclatureTypeFieldDeleteRequest {
    @IsNumber()
    @IsNotEmpty()
    public id: number;

    constructor(data?: Partial<NomenclatureTypeFieldDeleteRequest>) {
        this.id = data?.id;
    }
}
