import { IsArray, IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../../crud';

export class NomenclaturePositionGetListRequest extends CrudItemListRequest {
    /** Array with uuids of reference items for filter by category */
    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    public categoryUuids?: string[];

    /** Filter by text in the name */
    @IsOptional()
    @IsString()
    public search?: string;

    /** Filter by nomenclature type uuid */
    @IsOptional()
    @IsString()
    public filterByNomTypeUuid?: string;

    /** Exclude positions by uuid from result */
    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    public excludeByUuids?: string[];

    constructor(data?: Partial<NomenclaturePositionGetListRequest>) {
        super(data);
        this.categoryUuids = data?.categoryUuids || [];
        this.search = data?.search;
        this.filterByNomTypeUuid = data?.filterByNomTypeUuid;
        this.excludeByUuids = data?.excludeByUuids || [];
    }
}
