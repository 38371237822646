import { IsInt } from 'class-validator';

export class AreaRemovePersonRequest {
    @IsInt()
    public personId: number;

    @IsInt()
    public areaId: number;

    constructor(data?: Partial<AreaRemovePersonRequest>) {
        this.personId = data?.personId;
        this.areaId = data?.areaId;
    }
}
