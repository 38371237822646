import { IsBoolean, IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../../crud';

export class TaxonomyReferenceFieldListGetRequest extends CrudItemListRequest {
    @IsString()
    public referenceUuid: string;

    @IsBoolean()
    @IsOptional()
    public extendProperties?: boolean;

    constructor(data?: Partial<TaxonomyReferenceFieldListGetRequest>) {
        super(data);
        this.referenceUuid = data?.referenceUuid;
        this.extendProperties = data?.extendProperties;
    }
}
