import { IsBoolean, IsNotEmpty, IsString } from 'class-validator';

/**
 * @see backend-php/modules/rest_common/models/taxonomy/FieldReference.php
 */
export class FieldOptionsReference {
    @IsString()
    @IsNotEmpty()
    public referenceUuid: string;

    @IsBoolean()
    public multiple: boolean;

    constructor(data?: Partial<FieldOptionsReference>) {
        this.referenceUuid = data?.referenceUuid;
        this.multiple = data?.multiple;
    }
}
