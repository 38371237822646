import { IPlaneFrame } from './interfaces';
import { PlaneLine } from './PlaneLine';
import { PlaneRadiusVector } from './PlaneRadiusVector';

export class PlaneFrame implements IPlaneFrame {
    public lineTop: PlaneLine;
    public lineRight: PlaneLine;
    public lineBottom: PlaneLine;
    public lineLeft: PlaneLine;

    constructor(topLeft: PlaneRadiusVector, topRight: PlaneRadiusVector, bottomRight: PlaneRadiusVector, bottomLeft: PlaneRadiusVector) {
        this.lineTop = new PlaneLine(topLeft, topRight);
        this.lineRight = new PlaneLine(topRight, bottomRight);
        this.lineBottom = new PlaneLine(bottomRight, bottomLeft);
        this.lineLeft = new PlaneLine(bottomLeft, topLeft);
    }
}
