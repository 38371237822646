import { IsDefined, IsOptional, IsString, Length, ValidateIf } from 'class-validator';

export class UserLoginRequest {
    @ValidateIf((o: UserLoginRequest) => !o.token)
    @IsString()
    @Length(2, 255)
    public username?: string;

    @ValidateIf((o: UserLoginRequest) => !o.token)
    @IsDefined()
    @IsString()
    public password?: string;

    @IsOptional()
    @IsString()
    public token?: string;

    constructor(data?: Partial<UserLoginRequest>) {
        this.username = data && data.username;
        this.password = data && data.password;
        this.token = data && data.token;
    }
}
