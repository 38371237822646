import { IsArray, IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString, Matches, MaxLength, ValidateIf } from 'class-validator';

export abstract class AbstractPersonRequest {
    @IsString()
    @IsNotEmpty()
    @MaxLength(100)
    public firstName: string;

    @IsOptional()
    @IsString()
    @MaxLength(100)
    public middleName: string;

    @IsString()
    @IsNotEmpty()
    @MaxLength(100)
    public lastName: string;

    @ValidateIf((o: AbstractPersonRequest, value: string) => !!value || o.createUser)
    @IsEmail()
    public email: string;

    @ValidateIf((o: AbstractPersonRequest, value: string) => !!value || o.createUser)
    @Matches(/^\d{11}$/)
    public phone: string;

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    public roles: string[];

    @IsOptional()
    @IsString()
    @MaxLength(100)
    public comment: string;

    @IsBoolean()
    public createUser: boolean;

    constructor(data?: Partial<AbstractPersonRequest>) {
        this.firstName = data?.firstName;
        this.middleName = data?.middleName;
        this.lastName = data?.lastName;
        this.email = data?.email;
        this.phone = data?.phone;
        this.roles = data?.roles;
        this.comment = data?.comment;
        this.createUser = data?.createUser ?? false;
    }
}
