import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    CrudList, ECacheTag, ProfileContractorSourceAvailabilityRequest, ProfileContractorSourceAvailabilityResponse, ProfileGetListRequest, ProfileRaw,
    ProfileUpdateRequest, RestApiResponse,
} from '@ls-front/sharable';

import { ApiService } from './api.service';

@Injectable()
export class ProfileCatalogService {
    constructor(private api: ApiService) {
    }

    public getList(post?: ProfileGetListRequest): Observable<CrudList<ProfileRaw>> {
        return this.api.post<CrudList<ProfileRaw>>('/rest_api_v2/profile/get-list', post)
            .pipe(map(data => new CrudList(data, ProfileRaw)));
    }

    public remove(id: number): Observable<RestApiResponse> {
        return this.api.post<RestApiResponse>('/rest_api_v2/profile/delete', { id })
            .pipe(map(data => new RestApiResponse(data)));
    }

    /* обновить или создать */
    public update(request: ProfileUpdateRequest): Observable<RestApiResponse> {
        return this.api.post<RestApiResponse>('/rest_api_v2/profile/update', request)
            .pipe(map(data => new RestApiResponse(data)));
    }

    public contractorSourceAvailable(request: ProfileContractorSourceAvailabilityRequest): Observable<boolean> {
        return this.api.getCached<ProfileContractorSourceAvailabilityResponse>(
            '/rest_api_v2/profile/profile-contractor-source-availability', ECacheTag.contragent, request
        )
            .pipe(map((data: ProfileContractorSourceAvailabilityResponse) => data.contractorSourceAvailable));
    }

    public getPublicList(post?: ProfileGetListRequest): Observable<CrudList<ProfileRaw>> {
        return this.api.getCached<CrudList<ProfileRaw>>('/rest_api_v2/public/profile-get-list', ECacheTag.profileCatalog, post)
            .pipe(map(data => new CrudList(data, ProfileRaw)));
    }
}
