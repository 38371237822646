import { ValidatorFn, Validators } from '@angular/forms';

function isDefined(value: unknown): boolean {
    return value !== undefined && value !== null;
}

export class LsValidators {
    public static validatorRequired(required: boolean | null): ValidatorFn {
        if (required === true) {
            return Validators.required;
        }
        return Validators.nullValidator;
    }

    public static validatorPattern(pattern: string): ValidatorFn {
        if (!isDefined(pattern)) {
            return Validators.nullValidator;
        }
        return Validators.pattern(pattern);
    }

    public static validatorEmail(): ValidatorFn {
        return control => (control.value ? Validators.email(control) : null);
    }

    public static validatorNumber(): ValidatorFn {
        const regex = /(^\d+$)|(^\d+[.,]?\d+$)|(^[+-]?\d+$)|(^$)|(^[+-]?\d+[.,]?\d+$)/;
        return control => (control.value === null || regex.test(control.value) ? null :
            { pattern: { number: false, actualValue: control.value } });
    }

    public static validatorMinLength(minLength: number): ValidatorFn {
        if (!isDefined(minLength)) {
            return Validators.nullValidator;
        }
        return Validators.minLength(minLength);
    }

    public static validatorMaxLength(maxLength: number): ValidatorFn {
        if (!isDefined(maxLength)) {
            return Validators.nullValidator;
        }
        return Validators.maxLength(maxLength);
    }

    public static validatorStepValue(step: number | null, minValue: number = 0): ValidatorFn {
        if (!isDefined(step)) {
            return Validators.nullValidator;
        }
        return control => {
            let divValue = (+control.value - minValue) / step;
            divValue = Number((divValue).toFixed(3)); // Обход проблемы неточных вычислений
            return (divValue - Math.floor(divValue)) === 0 ? null
                : { stepvalue: { stepValue: step, actualValue: +control.value } };
        };
    }

    public static validatorMinValue(minValue: number | null): ValidatorFn {
        if (!isDefined(minValue)) {
            return Validators.nullValidator;
        }
        return control => (+control.value < minValue ?
            { minvalue: { requiredLength: minValue, actualValue: +control.value } } :
            null);
    }

    public static validatorMaxValue(maxValue: number | null): ValidatorFn {
        if (!isDefined(maxValue)) {
            return Validators.nullValidator;
        }
        return control => (+control.value > maxValue ?
            { maxvalue: { requiredLength: maxValue, actualValue: +control.value } } :
            null);
    }

    public static phoneNumber(): ValidatorFn {
        return control => {
            const v = typeof control.value === 'number' ? control.value.toString() : control.value;
            return v?.length > 0 && v?.length < 11 ? { phone: { valid: false, actualValue: control.value } } : null;
        };
    }
}
