import { EGroup } from '../EGroup';
import { Role } from '../Role';

export interface PersonConstruction {
    id?: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dealerName?: string;
    email?: string;
    groupTitle?: string;
    emailConfirmed?: boolean;
    lisaUser?: boolean;
    isActive?: boolean;
    isSystemItem?: boolean;
    isMarkDeleted?: boolean;
    comment?: string;
    createdAt?: number;
    dealerId?: number;
    gaugerId?: number;
    token?: string;
    group?: EGroup;
    lastLoginAt?: number;
    phone?: string;

    /**
     * The data ype should be turned to `string[]`. During the transition period PHP>>NodeJs we we keep mapping Role >> string by its name
     * todo: turn the data  type to `string[]`.
     */
    roles?: Array<Role | string>;
}

export class Person {
    public id: number;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public dealerName: string;
    public email: string;
    public groupTitle: string;
    public emailConfirmed: boolean;
    public lisaUser: boolean;
    public isActive: boolean;
    public isSystemItem: boolean;

    /** todo: rename to `isDeleted` */
    public isMarkDeleted: boolean;

    public comment: string;
    public createdAt: number;
    public dealerId: number;
    public gaugerId: number;
    public token: string;
    public group: EGroup;
    public lastLoginAt: number;
    public phone: string;
    public roles: string[];

    constructor(data?: PersonConstruction) {
        this.id = data?.id;
        this.firstName = data?.firstName;
        this.middleName = data?.middleName;
        this.lastName = data?.lastName;
        this.dealerName = data?.dealerName;
        this.email = data?.email;
        this.groupTitle = data?.groupTitle;
        this.emailConfirmed = data?.emailConfirmed ?? false;
        this.lisaUser = data?.lisaUser ?? false;
        this.isActive = data?.isActive ?? false;
        this.isSystemItem = data?.isSystemItem ?? false;
        this.isMarkDeleted = data?.isMarkDeleted ?? false;
        this.comment = data?.comment;
        this.createdAt = data?.createdAt;
        this.dealerId = data?.dealerId;
        this.gaugerId = data?.gaugerId;
        this.token = data?.token;
        this.group = data?.group;
        this.lastLoginAt = data?.lastLoginAt;
        this.phone = data?.phone;
        this.roles = Array.isArray(data?.roles) ? data.roles.map(r => (typeof r === 'string' ? r : r?.name)) : undefined;
    }
}
