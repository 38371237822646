import { RestApiResponse } from '../../common/RestApiResponse';
import { Reference } from '../Reference/Reference';

export class TaxonomyReferenceUpdateResponse extends RestApiResponse {
    public reference: Reference;

    constructor(data?: Partial<TaxonomyReferenceUpdateResponse>) {
        super(data);
        this.reference = data?.reference instanceof Reference ? data.reference : (data?.reference ? new Reference(data.reference) : undefined);
    }
}
