import { PlaneLabel, PlaneRadiusVector } from '../mathematic-plane';

import { PlaneProfileLine } from './PlaneProfileLine';

export class PlaneProfileLabel extends PlaneLabel {
    constructor(
        public parentLine: PlaneProfileLine,
        public text: string,
        public position: PlaneRadiusVector,
        public angle: number,
        public isAngleLabel: boolean
    ) {
        super(text, position, angle, isAngleLabel);
    }
}
