import { PlanePoint } from './PlanePoint';
import { IPlanePoint } from './interfaces';

export class PlaneRadiusVector extends PlanePoint {
    public static from(point: IPlanePoint): PlaneRadiusVector {
        return new PlaneRadiusVector(point.x, point.y);
    }

    public clone(): PlaneRadiusVector {
        return new PlaneRadiusVector(this.x, this.y);
    }

    public length(): number {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    public normal(): PlaneRadiusVector {
        const len = this.length();
        return new PlaneRadiusVector(this.x / len, this.y / len);
    }

    /** Returns square of length between this and requested point */
    public lengthSqrTo(point: IPlanePoint): number {
        return (this.x - point.x) * (this.x - point.x) + (this.y - point.y) * (this.y - point.y);
    }

    /** Returns length between this and requested point */
    public lengthTo(point: IPlanePoint): number {
        return Math.sqrt(this.lengthSqrTo(point));
    }

    /** returns summary point two points */
    public add(point: IPlanePoint): PlaneRadiusVector {
        return new PlaneRadiusVector(this.x + point.x, this.y + point.y);
    }

    /** returns the difference between the given and passed points */
    public delta(point: IPlanePoint): PlaneRadiusVector {
        return new PlaneRadiusVector(this.x - point.x, this.y - point.y);
    }

    /** returns a result of multiply to the number */
    public multiply(v: number): PlaneRadiusVector {
        return new PlaneRadiusVector(this.x * v, this.y * v);
    }

    /**
     * возвращает результат деления данного вектора на переданное число
     */
    public divide(v: number): PlaneRadiusVector {
        return new PlaneRadiusVector(this.x / v, this.y / v);
    }

    /**
     * возвращает z-компоненту векторного произведения данного вектора на переданный
     * (z-компоненты данного и переданного векторов полагаются равными 0)
     */
    public multiplyToVector(v: IPlanePoint): number {
        return this.x * v.y - v.x * this.y;
    }

    /**
     * возвращает сумму данного вектора и вектора, определяемого длиной и углом относительно горизонта, отсчитываемого по часовой стрелке
     */
    public addLength(alpha: number, length: number): PlaneRadiusVector {
        return new PlaneRadiusVector(
            this.x + length * Math.cos(alpha * Math.PI / 180),
            this.y + length * Math.sin(alpha * Math.PI / 180)
        );
    }
}
