import { IPlanePoint, MathPlane, PlanePoint, PlaneRadiusVector } from './index';

/**
 * Ensure rotating and scaling radius vectors
 */
export class PlaneRadiusVectorTransform {
    public center = new PlanePoint(0, 0); // центр преобразований
    public translate = new PlaneRadiusVector(0, 0); // ветор трансляции
    private internalScale = 1; // коэффициент сжатия/растяжения
    private internalRotateAngle = 0; // угол поповрота в градусах
    private matrix: number[] = [1, 0, 0, 1]; // матрица преобразования
    private rotate = 0; // угол поворота в радианах

    public get scale(): number {
        return this.internalScale;
    }

    public set scale(value: number) {
        this.internalScale = value;
        this.rebuildMatrix();
    }

    public get rotateAngle(): number {
        return this.internalRotateAngle;
    }

    public set rotateAngle(angleDegree: number) {
        this.internalRotateAngle = angleDegree;
        this.rotate = MathPlane.toRadians(angleDegree);
        this.rebuildMatrix();
    }

    /** transform the vector by the matrix */
    public apply(v: PlaneRadiusVector): PlaneRadiusVector {
        return this.getTransformed(v.delta(this.center)).add(this.center).add(this.translate.multiply(this.internalScale));
    }

    /** Recalculate the transformation matrix */
    private rebuildMatrix(): void {
        this.matrix[0] = this.internalScale * Math.cos(this.rotate);
        this.matrix[1] = this.internalScale * Math.sin(this.rotate);
        this.matrix[2] = -this.internalScale * Math.sin(this.rotate);
        this.matrix[3] = this.internalScale * Math.cos(this.rotate);
    }

    /** Multiplies a radius vector to the transformation matrix */
    private getTransformed(point: IPlanePoint): PlaneRadiusVector {
        return new PlaneRadiusVector(
            this.matrix[0] * point.x + this.matrix[1] * point.y,
            this.matrix[2] * point.x + this.matrix[3] * point.y
        );
    }
}
