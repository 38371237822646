/**
 * Created by optimistex on 02.06.17.
 */
import { OrderStatus } from '@ls-front/sharable';

export const orderViewRoutes: { [key in OrderStatus]: string } = {
    [OrderStatus.STATUS_NEW]: '/order/edit/',
    [OrderStatus.STATUS_IN_WORK]: '/order/in-work/',
    [OrderStatus.STATUS_CLOSED]: '/order/closed/',
    [OrderStatus.STATUS_SHIPPED]: '/order/shipped/',
};
