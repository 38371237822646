import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class NomenclaturePositionGetRequest {
    @IsString()
    public uuid: string;

    @IsOptional()
    @IsBoolean()
    public valuesAsText?: boolean;

    constructor(data?: Partial<NomenclaturePositionGetRequest>) {
        this.uuid = data?.uuid;
        this.valuesAsText = data?.valuesAsText || false;
    }
}
