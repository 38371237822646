export class LsStorage {
    public static set<T>(key: string, data: T): void {
        // try/catch added to catch Error: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (err) {
            console.error(err);
        }
    }

    public static get<T>(key: string): T {
        // try/catch added to catch Error: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (err) {
            console.error(err);
        }
    }

    public static remove(key: string): void {
        // try/catch added to catch Error: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
        try {
            localStorage.removeItem(key);
        } catch (err) {
            console.error(err);
        }
    }
}

