import { NomenclatureAccountingType } from './enum/NomenclatureAccountingType';

export class NomenclatureType {
    // Main
    public uuid: string;
    public name: string;
    public type: NomenclatureAccountingType;
    public isSystem: boolean;

    // Additional
    public priceFormula?: string;    // формула расчета стоимости единицы номенклатуры
    public quantityFormula?: string; // формула расчета количества в единицах измерения номенклатуры (см. f_measure)
    public isDeleted?: boolean;
    public isFranchise?: boolean;

    constructor(data?: Partial<NomenclatureType>) {
        this.uuid = data && data.uuid || null;
        this.name = data && data.name || '';
        this.type = data && data.type || NomenclatureAccountingType.ACCOUNTING_TYPE_PRODUCT;
        this.isSystem = data && data.isSystem || false;
        this.priceFormula = data && data.priceFormula || '';
        this.quantityFormula = data && data.quantityFormula || '';
        this.isDeleted = data && data.isDeleted || false;
        this.isFranchise = data?.isFranchise || false;
    }

    public static titleOfType(type: NomenclatureAccountingType): string {
        switch (type) {
            case NomenclatureAccountingType.ACCOUNTING_TYPE_PRODUCT:
                return 'Товар (совместимо с 1С)';
            case NomenclatureAccountingType.ACCOUNTING_TYPE_SERVICE:
                return 'Услуга';
            case NomenclatureAccountingType.ACCOUNTING_TYPE_KIT:
                return 'Комплект';
            default:
                return 'n/a';
        }
    }
}
