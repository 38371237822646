import { IsNotEmpty, IsString } from 'class-validator';

export class TaxonomyReferenceFieldDeleteRequest {
    @IsString()
    @IsNotEmpty()
    public uuid: string;

    constructor(data?: Partial<TaxonomyReferenceFieldDeleteRequest>) {
        this.uuid = data?.uuid;
    }
}
