import { RestApiResponse } from '../common/RestApiResponse';

export class ReferenceCopyResponse extends RestApiResponse {
    public newUuid: string;

    constructor(data?: Partial<ReferenceCopyResponse>) {
        super(data);
        this.newUuid = data && data.newUuid;
    }
}
