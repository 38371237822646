import { isEmpty as lodashIsEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

export function precisionRound(value: number, precision: number = 2): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
}

/**
 * Tracking id inside *ngFor
 */
export function trackById<T extends { id: number }>(index: number, item: T): number {
    return item.id;
}

export function trackByUuid<T extends { uuid: string }>(index: number, item: T): string {
    return item.uuid;
}

/** Generating UUID */
export function generateUuid(): string {
    return uuidV4();
}

/**
 * Checking value for null
 */
export function isNull<T>(value: T): boolean {
    return typeof value === 'undefined' || value === null;
}

export function isEmpty<T>(value: T): boolean {
    switch (typeof value) {
        case 'string':
            return !value.length;
        case 'number':
            return false;
        case 'object':
            return lodashIsEmpty(value);
    }
    return !value;
}
