import { RestApiResponse } from '../../common/RestApiResponse';

import { UserLoginInfo } from './UserLoginInfo';

interface UserLoginResponseConstruction extends RestApiResponse {
    userLoginInfo?: Partial<UserLoginInfo>;
}

export class UserLoginResponse extends RestApiResponse {
    public userLoginInfo: UserLoginInfo;

    constructor(data?: UserLoginResponseConstruction) {
        super(data);
        this.userLoginInfo = data?.userLoginInfo ? new UserLoginInfo(data.userLoginInfo) : undefined;
    }
}
