import { ITPDateModel } from './ITPDateModel';
import { ITPDate } from './ITPDate';

export interface TPDateModelRaw {
    date?: ITPDate;
    jsdate: string;
    epoc?: number;
    formatted?: string;
}

export class TPDateModel implements ITPDateModel {
    public date: ITPDate;
    public jsdate: Date;
    public epoc: number;
    public formatted: string;

    constructor(data: Partial<TPDateModelRaw>) {
        this.epoc = data && data.epoc;
        this.formatted = data && data.formatted;
        if (data) {
            this.jsdate = new Date(data.jsdate);
        }

        this.date = {
            day: data && data.date && data.date.day,
            month: data && data.date && data.date.month,
            year: data && data.date && data.date.year,
        };
    }
}
