export class CrudPaginateResponse {
    public itemsCount?: number;
    public pageSize?: number;
    public pagesCount?: number;

    constructor(data?: Partial<CrudPaginateResponse>) {
        this.itemsCount = data && data.itemsCount || undefined;
        this.pageSize = data && data.pageSize || undefined;
        this.pagesCount = data && data.pagesCount || undefined;
    }
}
