import { IsNumber, ValidateNested } from 'class-validator';

import { IOrderExternalData } from './IOrderExternalData';
import { OrderExternalData } from './OrderExternalData';

export class OrderExternalDataUpdateRequest {
    @IsNumber()
    public orderId: number;

    @ValidateNested()
    public externalData: IOrderExternalData;

    constructor(data: Partial<OrderExternalDataUpdateRequest>) {
        this.orderId = data.orderId;
        this.externalData = new OrderExternalData(data.externalData);
    }
}
