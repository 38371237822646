import { IsNumber } from 'class-validator';

export class OrderPaymentCreateRequest {
    @IsNumber()
    public orderId: number;

    constructor(data?: Partial<OrderPaymentCreateRequest>) {
        this.orderId = data?.orderId;
    }
}
