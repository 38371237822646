export class ProfileRaw {
    public id?: number;
    public name?: string;
    public profile?: string;

    constructor(data?: Partial<ProfileRaw>) {
        this.id = data && data.id;
        this.name = data && data.name;
        this.profile = data && data.profile;
    }
}
