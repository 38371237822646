import { IsEmail, IsInt, IsOptional, IsString } from 'class-validator';

export class WidgetCallbackRequest {
    @IsInt()
    public orderId: number;

    @IsInt()
    public contractorId: number;

    @IsOptional()
    @IsString()
    public name?: string;

    @IsOptional()
    @IsString()
    @IsEmail()
    public email?: string;

    @IsOptional()
    @IsString()
    public phone?: string;

    @IsOptional()
    @IsString()
    public comment?: string;

    constructor(data?: Partial<WidgetCallbackRequest>) {
        this.orderId = data?.orderId;
        this.contractorId = data?.contractorId;
        this.name = data?.name;
        this.email = data?.email;
        this.phone = data?.phone;
        this.comment = data?.comment;
    }
}
