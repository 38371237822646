import { IsIn, IsInt, IsOptional, IsString, MaxLength } from 'class-validator';

import { AreaType } from './AreaType';

export class AreaUpdateRequest {
    @IsOptional()
    @IsInt()
    public readonly id?: number;

    @IsString()
    @MaxLength(64)
    public readonly name: string;

    @IsString()
    @MaxLength(2)
    public readonly tag: string;

    @IsInt()
    @IsIn([
        AreaType.default,
        // AreaType.layoutLinear,
        AreaType.layout2D,
    ])
    public readonly typeId: AreaType;

    constructor(data?: Partial<AreaUpdateRequest>) {
        this.id = data && data.id;
        this.name = data && data.name;
        this.tag = data && data.tag;
        this.typeId = data && data.typeId;
    }
}
