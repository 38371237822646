import { IsInt, IsNotEmpty, IsOptional, IsString, MaxLength } from 'class-validator';

export class ProfileUpdateRequest {
    @IsOptional()
    @IsInt()
    public id?: number;

    @IsString()
    @IsNotEmpty()
    @MaxLength(255)
    public name: string;

    @IsString()
    @IsNotEmpty()
    public profile: string;

    constructor(data?: Partial<ProfileUpdateRequest>) {
        this.id = data && data.id;
        this.name = data && data.name;
        this.profile = data && data.profile;
    }
}
