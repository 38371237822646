import { RestApiResponse } from '../../common/RestApiResponse';

export class NomenclatureTypeCopyResponse extends RestApiResponse {
    public newUuid: string;

    constructor(data?: Partial<NomenclatureTypeCopyResponse>) {
        super(data);
        this.newUuid = data && data.newUuid || undefined;
    }
}
