import { RestApiResponse } from '../common/RestApiResponse';

import { Area } from './area';

interface AreaGetResponseConstruction extends RestApiResponse {
    item: Partial<Area>;
}

export class AreaGetResponse extends RestApiResponse {
    public item: Area;

    constructor(data?: AreaGetResponseConstruction) {
        super(data);
        this.item = data?.item ? new Area(data.item) : undefined;
    }
}
