import { Role } from '../Role';
import { RestApiResponse } from '../../common/RestApiResponse';

export class RoleGetResponse extends RestApiResponse {
    public role: Role;

    constructor(data?: Partial<RoleGetResponse>) {
        super(data);
        this.role = data && data.role;
    }
}
