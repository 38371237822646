export enum NomenclatureAccountingType {
    /** The type of accounting - product */
    ACCOUNTING_TYPE_PRODUCT = 1,

    /** The type of accounting - service */
    ACCOUNTING_TYPE_SERVICE = 2,

    /** The type of accounting - kit */
    ACCOUNTING_TYPE_KIT = 3,
}
