import { IsIn, IsInt } from 'class-validator';

export class PersonBlockUserRequest {
    @IsInt()
    public personId: number;

    @IsIn(['block', 'unBlock'])
    public action: 'block' | 'unBlock';

    constructor(data?: Partial<PersonBlockUserRequest>) {
        this.personId = data?.personId;
        this.action = data?.action;
    }
}
