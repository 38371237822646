import { IsInt, IsNotEmpty } from 'class-validator';

export class PersonGetRequest {
    @IsInt()
    @IsNotEmpty()
    public personId: number;

    constructor(data?: Partial<PersonGetRequest>) {
        this.personId = data?.personId;
    }
}
