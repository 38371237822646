import { IsInt } from 'class-validator';

export class AreaDeleteRequest {
    @IsInt()
    public id: number;

    constructor(data?: Partial<AreaDeleteRequest>) {
        this.id = data?.id;
    }
}
