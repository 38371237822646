import { IsInt, IsNotEmpty, IsString, Min } from 'class-validator';

export class NomenclaturePositionUpdateStockRequest {
    @IsString()
    @IsNotEmpty()
    public externalCode: string;

    @IsInt()
    @Min(0)
    public stock: number;

    constructor(data?: Partial<NomenclaturePositionUpdateStockRequest>) {
        this.externalCode = data?.externalCode;
        this.stock = data?.stock;
    }
}
