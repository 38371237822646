import { IsInt, IsNotEmpty } from 'class-validator';

export class PersonSendActivationEmailRequest {
    @IsInt()
    @IsNotEmpty()
    public id: number;

    constructor(data?: Partial<PersonSendActivationEmailRequest>) {
        this.id = data?.id;
    }
}
