export enum NomPosChildDataSource {
    /**
     * Used to skip/exclude unnecessary composition part.
     * @example In a mosquito netting a crossbar may be optional. So, it can be excluded for a specific nomenclature position.
     */
    exclude = 1,

    /**
     * When a nomenclature type has few the same child nomenclature types it's possible to configure just one and copy this config to the others.
     * @example A mosquito netting has the frame from 4 slats (top, bottom, left, right).
     * All of these 4 slats are going to be done from the same material, let's say they are going to pe produces from the same nomenclature position.
     * So, it is possible to configure just one of the slats and for the others set to copy the options from the first one.
     * @see NomenclaturePositionChildEntity.sourceUuid
     */
    copyFrom = 2,

    /**
     * Let's to define child position of the composition directly in the catalog. So, in each order it is going to be auto selected automatically.
     */
    selectAtCatalog = 3,

    /**
     * Defines that a child position of the composition should be selected by user in each order.
     * For selecting the child position will be provided all exiting nomenclature positions of the relevant nomenclature type.
     */
    selectAtOrder = 4,

    /**
     * Defines that a child position of the composition should be selected by user in each order.
     * The list of the selecting child positions should be be defined in the catalog.
     * @see NomenclaturePositionChildEntity.options
     */
    selectAtOrderByList = 5,

    /**
     * todo: not implemented
     * Defines that a child position of the composition should be selected by user in each order.
     * The list of the selecting child positions should be be defined in the catalog and filtered in the order editor by the specified conditions.
     * @see NomenclaturePositionChildOptionEntity.condition
     */
    selectAtOrderByConditionList = 6,
}
