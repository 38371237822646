import { LogLevel } from './LogLevel';
import { LoggerTransport } from './interfaces/LoggerTransport';
import { Logger } from './interfaces/logger';

export class LoggerEngine<Context> implements Logger<Context> {
    private transports: LoggerTransport[] = [];

    public addTransport(transport: LoggerTransport): void {
        this.transports.push(transport);
    }

    public log(message: string, context?: Context): void {
        this.persist(LogLevel.log, message, context);
    }

    public info(message: string, context?: Context): void {
        this.persist(LogLevel.info, message, context);
    }

    public warn(message: string, context?: Context): void {
        this.persist(LogLevel.warn, message, context);
    }

    public error(message: string, context?: Context): void {
        this.persist(LogLevel.error, message, context);
    }

    protected persist(level: LogLevel, message: string, context?: Context): void {
        this.transports.forEach(transport => {
            try {
                transport.log(level, message, this.cloneAndSanitize(context));
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(`Logger.persist ${transport.constructor.name}`, e);
            }
        });
    }

    private cloneAndSanitize(data: unknown): unknown {
        // noinspection SpellCheckingInspection
        const sensitivePropertyNameParts = ['password', 'secret', 'apikey', 'api-key', 'keyapi', 'key-api'];
        const cache: unknown[] = [];
        return this.cloneWith(data, (value, key) => {
            if (value && typeof value === 'object') {
                if (cache.indexOf(value) > -1) {
                    return '[~cycled link]';
                }
                cache.push(value);
            }
            return typeof value === 'string' && sensitivePropertyNameParts.find(part => key.toLowerCase().search(part) > -1) ? '***' + value.substr(-1) : value;
        });
    }

    private cloneWith(data: unknown, callback: (value: unknown, key: string) => unknown): unknown {
        if (Array.isArray(data)) {
            return data.map(item => this.cloneWith(item, callback));
        }
        if (data && typeof data === 'object') {
            const rData = data as Record<string, unknown>;
            const result: Record<string, unknown> = {};
            const propNameList = Object.getOwnPropertyNames(rData);
            for (const propName of propNameList) {
                if (Object.hasOwnProperty.apply(rData, [propName])) {
                    result[propName] = this.cloneWith(callback(rData[propName], propName), callback);
                }
            }
            return result;
        }
        return data;
    }
}
