import { IsInt } from 'class-validator';

export class ContragentDeleteRequest {
    @IsInt()
    public id: number;

    constructor(data?: Partial<ContragentDeleteRequest>) {
        this.id = data && data.id;
    }
}
