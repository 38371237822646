import { IsIn, IsString } from 'class-validator';

export class SortOptions {
    @IsString()
    public byField = '';

    @IsIn(['asc', 'desc'])
    public direction: 'asc' | 'desc' = 'asc';

    constructor(byField = '', direction: 'asc' | 'desc' = 'asc') {
        this.byField = byField;
        this.direction = direction;
    }

    public static create(data: Partial<SortOptions>): SortOptions {
        return new SortOptions(
            data && data.byField || '',
            data && data.direction || 'asc'
        );
    }
}
