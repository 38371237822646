import { IsEnum, IsOptional, IsString } from 'class-validator';

import { EGroup } from '../../EGroup';

export class RoleListRequest {
    @IsOptional()
    @IsString()
    @IsEnum(EGroup)
    public group?: EGroup;

    constructor(data?: Partial<RoleListRequest>) {
        this.group = data && data.group || undefined;
    }
}
