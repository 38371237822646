
import { IsNotEmpty, IsString } from 'class-validator';

export class NomenclatureTypeFormulaVariablesGetRequest {
    @IsString()
    @IsNotEmpty()
    public nomTypeUuid: string;

    constructor(data?: Partial<NomenclatureTypeFormulaVariablesGetRequest>) {
        this.nomTypeUuid = data?.nomTypeUuid;
    }
}
