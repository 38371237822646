import * as sentry from '@sentry/angular';
import { LogLevel, LoggerTransport } from '@ls-front/sharable';

export class SentryLoggerTransport implements LoggerTransport {
    constructor(private showReportDialogOnError: boolean) {
    }

    public log(level: LogLevel, message: string, context: unknown): void {
        /**
         * Sentry Manual Breadcrumbs
         * You can manually add breadcrumbs whenever something interesting happens.
         * For example, you might manually record a breadcrumb if the user authenticates or another state change occurs.
         * @see https://docs.sentry.io/platforms/javascript/guides/angular/enriching-events/breadcrumbs/#manual-breadcrumbs
         */
        switch (level) {
            case LogLevel.log:
                sentry.addBreadcrumb({ level: 'log', message, data: context });
                break;
            case LogLevel.info:
                sentry.addBreadcrumb({ level: 'info', message, data: context });
                break;
            case LogLevel.warn:
                sentry.addBreadcrumb({ level: 'warning', message, data: context });
                break;
            case LogLevel.error:
                sentry.addBreadcrumb({ level: 'error', message });
                const eventId = sentry.captureException(context);
                if (this.showReportDialogOnError) {
                    sentry.showReportDialog({ eventId });
                }
        }
    }
}
