import { TaxonomyValues, TaxonomyValuesContainer } from '../../taxonomy/index';

import { OrderPositionCost } from './order-position-cost';

/**
 * @deprecated we need to get rid of it
 */
export interface OrderPositionDataValue {
    [valueId: number]: unknown;

    params: { title: string; value: string; printOnBill: boolean }[];
}

export class OrderPosition implements TaxonomyValuesContainer {
    public id?: number;
    public orderId?: number;
    public qty?: number;

    /** @see OrderPositionEntity.price */
    public price?: number;

    /** @see OrderPositionEntity.totalPrice */
    public totalPrice?: number;

    /** @see OrderPositionEntity.sum */
    public sum?: number;
    public cost?: OrderPositionCost;
    public comment?: string;
    public sort?: number;
    public name?: string;
    public nomTypeUuid?: string;
    public nomPositionUuid?: string;
    public completed?: boolean;
    public contrId?: number;

    public values?: TaxonomyValues;

    // todo: going to kill that
    public dataValue?: OrderPositionDataValue;

    constructor(data?: Partial<OrderPosition>) {
        this.id = data?.id;
        this.orderId = data?.orderId;
        this.qty = data?.qty;
        this.price = data?.price;
        this.totalPrice = data?.totalPrice;
        this.sum = data?.sum;
        this.cost = data?.cost;
        this.comment = data?.comment;
        this.sort = data?.sort;
        this.name = data?.name;
        this.nomTypeUuid = data?.nomTypeUuid;
        this.nomPositionUuid = data?.nomPositionUuid;
        this.completed = data?.completed || false;
        this.contrId = data?.contrId;
        this.values = data?.values;
        this.dataValue = data?.dataValue;
    }
}
