import { IsEnum, IsNotEmpty, IsOptional, IsString, ValidateIf } from 'class-validator';

import { FieldOptionsStringFormatType } from './FieldOptionsStringFormatType';

/**
 * @see backend-php/modules/rest_common/models/taxonomy/FieldString.php
 */
export class FieldOptionsString {
    @IsOptional()
    @IsString()
    public format: string;

    @ValidateIf((o: FieldOptionsString) => !!o.formatType || !!o.format)
    @IsNotEmpty()
    @IsEnum(FieldOptionsStringFormatType, { message: 'formatType неизвестный тип формата поля таксономии' })
    public formatType: FieldOptionsStringFormatType;

    constructor(data?: Partial<FieldOptionsString>) {
        this.format = data?.format;
        this.formatType = data?.formatType;
    }
}
