import { UntypedFormGroup } from '@angular/forms';

export class LsFormGroup extends UntypedFormGroup {
    public setValue(value: { [key: string]: unknown }, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
        const tmpObject: Record<string, unknown> = {};
        for (const controlName in this.controls) {
            if (value.hasOwnProperty(controlName)) {
                tmpObject[controlName] = value[controlName] === undefined ? null : value[controlName];
            }
        }
        super.setValue(tmpObject, options);
    }
}
