import { IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../crud';

export class BaseFieldGetListRequest extends CrudItemListRequest {
    @IsOptional()
    @IsString()
    public filterByReferenceUuid?: string;

    @IsOptional()
    @IsString()
    public filterByNomenclatureTypeUuid?: string;

    constructor(data?: Partial<BaseFieldGetListRequest>) {
        super(data);
        this.filterByReferenceUuid = data && data.filterByReferenceUuid;
        this.filterByNomenclatureTypeUuid = data && data.filterByNomenclatureTypeUuid;
    }
}
