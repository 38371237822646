import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MessengerService } from '../_service/messenger.service';
import { SessionService } from '../_service/session/session.service';

@Injectable()
export class LoginGuard  {
    constructor(private router: Router, private msg: MessengerService, private session: SessionService) {
    }

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.session.userSession$.pipe(
            take(1),
            map(userSession => {
                if (userSession.isLoggedIn()) {
                    this.msg.error('Доступ возможен, только для не авторизованного пользователя. ' +
                        'Пожалуйста выйдите из учетной записи и повторите попытку');
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            })
        );
    }
}
