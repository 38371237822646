import { IsOptional, IsString } from 'class-validator';

export class ContactUpdateRequest {
    @IsString()
    @IsOptional()
    public readonly uuid?: string;

    @IsString()
    @IsOptional()
    public readonly contactPhone?: string;

    @IsString()
    @IsOptional()
    public readonly contactPerson?: string;

    constructor(data?: Partial<ContactUpdateRequest>) {
        this.uuid = data?.uuid;
        this.contactPerson = data?.contactPerson || '';
        this.contactPhone = data?.contactPhone || '';
    }
}
