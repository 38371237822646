import { createMask } from 'imask';

import { Injectable } from '@angular/core';

function unmask(value: string | number): string {
    switch (typeof value) {
        case 'string':
            return value.replace(/\D+/g, '');
        case 'number':
            return value.toString();
        default:
            return '';
    }
}

/**
 * @deprecated Consider to move these functions to a separate helper functions
 */
@Injectable()
export class PhoneMaskService {
    public mask(value: string): string {
        if (!value) {
            return '';
        }
        const mask = createMask({ mask: '+0 (000) 000-00-00' });
        mask.unmaskedValue = value;
        return mask.value;
    }

    public unmask(value: string): string {
        return unmask(value);
    }
}
