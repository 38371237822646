import { CacheOptions } from './cache-options';
import { CacheStorageAbstract } from './cache-storage-abstract';

export class Cache {
    private taggedKeys: { [tag: string]: Set<string> } = {};

    constructor(private storage: CacheStorageAbstract, private prefix: string = 'Cache') {
    }

    /** Set data to cache */
    public set(key: string, value: any, options?: CacheOptions): boolean {
        const storageKey = this.toStorageKey(key);
        const expires = options && options.maxAge ? Date.now() + (options.maxAge * 1000) : Number.MAX_VALUE;
        if (!this.storage.setItem(storageKey, { value, expires })) {
            return false;
        }
        if (options && options.tag) {
            if (this.taggedKeys[options.tag]) {
                this.taggedKeys[options.tag].add(storageKey);
            } else {
                this.taggedKeys[options.tag] = new Set([storageKey]);
            }
        }
        return true;
    }

    /** Get data from cache */
    public get(key: string): any {
        const storageValue = this.storage.getItem(this.toStorageKey(key));
        if (storageValue) {
            if (!!storageValue.expires && storageValue.expires > Date.now()) {
                return storageValue.value;
            } else {
                this.remove(key);
            }
        }
        return null;
    }

    /** Check if value exists */
    public exists(key: string): boolean {
        return !!this.get(key);
    }

    /** Remove item from cache */
    public remove(key: string): void {
        const storageKey = this.toStorageKey(key);
        this.storage.removeItem(storageKey);
        for (const set of Object.values(this.taggedKeys)) {
            if (set.has(storageKey)) {
                set.delete(storageKey);
                break;
            }
        }
    }

    /** Remove all by tag */
    public removeTag(tag: string): void {
        if (this.taggedKeys[tag]) {
            [...this.taggedKeys[tag]].forEach(key => this.storage.removeItem(key));
            delete this.taggedKeys[tag];
        }
    }

    /** Remove all from cache */
    public removeAll(): void {
        this.storage.clear();
    }

    private toStorageKey(key: string): string {
        return this.prefix + key;
    }
}
