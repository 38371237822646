import { NomenclatureTypeChildParameter } from './NomenclatureTypeChildParameter';

export class NomenclatureTypeChild {
    public id?: number;
    public nomTypeUuidParent: string;
    public nomTypeUuidChild?: string;
    public title?: string;
    public name?: string;
    public formulaQuantity?: string;
    public required?: boolean;
    public requiredCondition?: string;
    public usageCondition?: string;
    public parameters?: NomenclatureTypeChildParameter[];

    public nomTypeUuid?: string;
    public nomTypeName?: string;

    constructor(data?: Partial<NomenclatureTypeChild>) {
        this.id = data && data.id;
        this.nomTypeUuidParent = data && data.nomTypeUuidParent;
        this.nomTypeUuidChild = data && data.nomTypeUuidChild;
        this.title = data && data.title;
        this.name = data && data.name;
        this.formulaQuantity = data && data.formulaQuantity;
        this.required = data && data.required;
        this.requiredCondition = data && data.requiredCondition;
        this.usageCondition = data && data.usageCondition;
        this.parameters = Array.isArray(data?.parameters) ? data.parameters.map(p => new NomenclatureTypeChildParameter(p)) : [];
        this.nomTypeUuid = data && data.nomTypeUuid;
        this.nomTypeName = data && data.nomTypeName;
    }
}
