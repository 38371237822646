import { IsString } from 'class-validator';

export class RoleDeleteRequest {
    @IsString()
    public roleName: string;

    constructor(data?: Partial<RoleDeleteRequest>) {
        this.roleName = data && data.roleName;
    }
}
