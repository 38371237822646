export interface RestApiResponseMessage {
    code: number;
    text: string;
}

export class RestApiResponse {
    public messages?: RestApiResponseMessage[];
    public success?: boolean;

    constructor(data: Partial<RestApiResponse>) {
        this.messages = data?.messages || undefined;
        this.success = data?.success;
    }
}
