import { ArrayNotEmpty, IsNotEmpty, IsString } from 'class-validator';

export class TaxonomyReferenceFieldListUpdateSortRequest {
    @ArrayNotEmpty()
    @IsString({ each: true })
    @IsNotEmpty({ each: true })
    public referenceFieldUuids: string[];

    constructor(data?: Partial<TaxonomyReferenceFieldListUpdateSortRequest>) {
        this.referenceFieldUuids = data?.referenceFieldUuids;
    }
}
