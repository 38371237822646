import { StorageValue } from './storage-value';

export abstract class CacheStorageAbstract {
    public abstract getItem(key: string): StorageValue;

    public abstract setItem(key: string, value: StorageValue): boolean;

    public abstract removeItem(key: string): void;

    public abstract clear(): void;
}
