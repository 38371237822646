export class Contact {
    public uuid?: string;
    public contactPhone?: string;
    public contactPerson?: string;

    constructor(data?: Partial<Contact>) {
        this.uuid = data?.uuid;
        this.contactPhone = data?.contactPhone;
        this.contactPerson = data?.contactPerson;
    }
}
