import { IPlaneFrame, IPlanePoint, PlaneArc, PlaneLine, PlaneRadiusVector } from './mathematic-plane';
import { PlaneRadiusVectorTransform } from './mathematic-plane/PlaneRadiusVectorTransform';
import { EProfilePaintSide } from './profile-items/EProfilePaintSide';
import { IProfileRenderer } from './IProfileRenderer';
import { ProfileRenderEngine } from './ProfileRenderEngine';

interface IProfileCanvasRenderStyle {
    font: string;
    canvasColor: string;
    paintSideColor: string;
}

export class ProfileCanvasRenderer implements IProfileRenderer {
    /** For debugging purposes */
    private drawFrames = false;

    constructor(
        private readonly ctx: CanvasRenderingContext2D,
        private readonly renderEngine: ProfileRenderEngine,
        private readonly style: IProfileCanvasRenderStyle
    ) {
        if (this.renderEngine.getTextSize) {
            // eslint-disable-next-line no-console
            console.warn('ProfileRenderEngine.getTextSize is assigned already');
        } else {
            this.renderEngine.getTextSize = text => {
                try {
                    return { width: this.ctx.measureText(text).width + 5, height: this.ctx.measureText('11').width + 5 };
                } catch (e) {
                    // this.ctx.measureText() throws an error in the PaleMoon Browser
                    const averageSymbolWidth = 5.561523437;
                    return { width: ('' + text).length * averageSymbolWidth + 4, height: 2 * averageSymbolWidth + 4 };
                }
            };
        }
    }

    public clearCanvas(): void {
        this.ctx.fillStyle = this.style.canvasColor;
        this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    }

    public renderBlueprint(): void {
        this.ctx.save();
        this.ctx.setLineDash([0]);
        this.renderLines(this.renderEngine.blueprintLines, '#000', '#f34640');
        this.ctx.restore();
    }

    public drawTopPaint(): void {
        this.ctx.save();
        this.ctx.setLineDash([4]);
        this.renderLines(this.renderEngine.topPaintLines, this.style.paintSideColor, '#f34640');
        this.ctx.restore();
    }

    public drawBottomPaint(): void {
        this.ctx.save();
        this.ctx.setLineDash([4]);
        this.renderLines(this.renderEngine.bottomPaintLines, this.style.paintSideColor, '#f34640');
        this.ctx.restore();
    }

    public drawLabels(): void {
        this.ctx.save();
        this.ctx.font = this.style.font;
        this.ctx.fillStyle = '#000';
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'middle';
        this.renderEngine.labels.forEach(label => {
            if (label.trackLine) {
                this.ctx.beginPath();
                this.ctx.moveTo(label.trackLine.start.x, label.trackLine.start.y);
                this.ctx.lineTo(label.trackLine.end.x, label.trackLine.end.y);
                this.ctx.stroke();
            }

            if (this.drawFrames && label.textFrame) {
                this.drawFrame(label.textFrame);
            }

            this.ctx.save();
            this.ctx.translate(label.position.x, label.position.y);
            this.ctx.rotate(label.angle / 180 * Math.PI);
            this.ctx.fillText(label.text, 0, 0);
            this.ctx.restore();
        });
        this.ctx.restore();
        if (this.drawFrames) {
            this.drawFrame(this.renderEngine.viewDimensions);
        }
    }

    public drawCursor(startLinePoint: IPlanePoint, cursorPosition: IPlanePoint, lineLength: number, lineAngle: number): void {
        this.ctx.save();
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = '#000';

        // Axes
        this.ctx.setLineDash([5]);
        this.ctx.beginPath();
        this.ctx.moveTo(cursorPosition.x - 25, cursorPosition.y);
        this.ctx.lineTo(0, cursorPosition.y);
        this.ctx.moveTo(cursorPosition.x, cursorPosition.y - 25);
        this.ctx.lineTo(cursorPosition.x, 0);
        this.ctx.stroke();

        // Cross + live line
        this.ctx.setLineDash([0]);
        this.ctx.beginPath();
        this.ctx.rect(cursorPosition.x - 5, cursorPosition.y - 5, 10, 10);
        this.ctx.moveTo(cursorPosition.x - 20, cursorPosition.y);
        this.ctx.lineTo(cursorPosition.x + 20, cursorPosition.y);
        this.ctx.moveTo(cursorPosition.x, cursorPosition.y - 20);
        this.ctx.lineTo(cursorPosition.x, cursorPosition.y + 20);
        if (startLinePoint) {
            this.ctx.moveTo(startLinePoint.x, startLinePoint.y);
            this.ctx.lineTo(cursorPosition.x, cursorPosition.y);
        }
        this.ctx.stroke();

        // Hints
        this.ctx.font = '12pt Arial';
        this.ctx.fillStyle = '#000';
        if (lineAngle) {
            this.ctx.fillText('Угол:  ' + lineAngle + '°', cursorPosition.x + 10.5, cursorPosition.y + 15.5);
        }
        if (lineLength) {
            this.ctx.fillText('Длина: ' + lineLength + 'мм', cursorPosition.x + 10.5, cursorPosition.y + 28.5);
        }

        this.ctx.restore();
    }

    public drawSvg(width: number, height: number, paddingSize: number, paintSide: EProfilePaintSide): void {
        const viewTransform = new PlaneRadiusVectorTransform();
        viewTransform.center = new PlaneRadiusVector(width / 2, height / 2);

        this.renderEngine.adjustView(viewTransform, width, height, paddingSize);
        this.renderEngine.prepareProfileToRender();
        this.clearCanvas();
        this.renderBlueprint();
        if ([EProfilePaintSide.paintBottom, EProfilePaintSide.paintBothSides].indexOf(paintSide) > -1) {
            this.renderEngine.prepareBottomPaintToRender();
            this.drawBottomPaint();
        }
        if ([EProfilePaintSide.paintTop, EProfilePaintSide.paintBothSides].indexOf(paintSide) > -1) {
            this.renderEngine.prepareTopPaintToRender();
            this.drawTopPaint();
        }
        this.renderEngine.prepareLabelsToRender();
        this.drawLabels();
    }

    private renderLines(lines: Array<PlaneLine | PlaneArc>, color: string, highlightedColor: string): void {
        this.renderLines2(lines, color, highlightedColor);
        return;

        // if (!lines.length) {
        //     return;
        // }
        // this.ctx.lineWidth = 2;
        // this.ctx.lineCap = 'round';
        // this.ctx.lineJoin = 'round';
        // let isHighlighted: boolean = null;
        // lines.forEach((item, i) => {
        //     if (item instanceof PlaneArc) {
        //         const arc: PlaneArc = item;
        //         this.ctx.arc(arc.center.x, arc.center.y, arc.radius, arc.startAngle, arc.endAngle, arc.anticlockwise);
        //     } else if (item instanceof PlaneLine) {
        //         const line: PlaneLine = item;
        //         if (isHighlighted !== line.isHighlighted) {
        //             isHighlighted = line.isHighlighted;
        //             if (i !== 0) {
        //                 this.ctx.stroke();
        //             }
        //             this.ctx.strokeStyle = isHighlighted ? highlightedColor : color;
        //             this.ctx.beginPath();
        //             this.ctx.moveTo(line.start.x, line.start.y);
        //         }
        //         this.ctx.lineTo(line.end.x, line.end.y);
        //     }
        // });
        // this.ctx.stroke();
    }

    private renderLines2(lines: Array<PlaneLine | PlaneArc>, color: string, highlightedColor: string): void {
        if (!lines.length) {
            return;
        }
        this.ctx.lineWidth = 2;
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round';
        lines.forEach(item => {
            this.ctx.beginPath();
            if (item instanceof PlaneArc) {
                const arc: PlaneArc = item;
                this.ctx.arc(arc.center.x, arc.center.y, arc.radius, arc.startAngle, arc.endAngle, arc.anticlockwise);
            } else if (item instanceof PlaneLine) {
                const line: PlaneLine = item;
                this.ctx.strokeStyle = line.isHighlighted ? highlightedColor : color;
                this.ctx.moveTo(line.start.x, line.start.y);
                this.ctx.lineTo(line.end.x, line.end.y);
            }
            this.ctx.stroke();
        });
    }

    private drawFrame(frame: IPlaneFrame): void {
        this.ctx.beginPath();
        this.ctx.moveTo(frame.lineTop.start.x, frame.lineTop.start.y);
        this.ctx.lineTo(frame.lineTop.end.x, frame.lineTop.end.y);

        this.ctx.lineTo(frame.lineRight.start.x, frame.lineRight.start.y);
        this.ctx.lineTo(frame.lineRight.end.x, frame.lineRight.end.y);

        this.ctx.lineTo(frame.lineBottom.start.x, frame.lineBottom.start.y);
        this.ctx.lineTo(frame.lineBottom.end.x, frame.lineBottom.end.y);

        this.ctx.lineTo(frame.lineLeft.start.x, frame.lineLeft.start.y);
        this.ctx.lineTo(frame.lineLeft.end.x, frame.lineLeft.end.y);
        this.ctx.stroke();
    }
}
