import { IsInt } from 'class-validator';

export interface ProfileGetSvgRequestConstruction {
    nomUnitId?: string;
    nomTypeFieldId?: string;
}

export class ProfileGetSvgRequest {
    @IsInt()
    public nomUnitId: number;

    @IsInt()
    public nomTypeFieldId: number;

    constructor(data?: ProfileGetSvgRequestConstruction) {
        this.nomUnitId = data?.nomUnitId !== undefined ? parseInt(data?.nomUnitId, 10) : undefined;
        this.nomTypeFieldId = data?.nomTypeFieldId !== undefined ? parseInt(data?.nomTypeFieldId, 10) : undefined;
    }
}
