import { IsArray, IsEnum, IsOptional, IsString } from 'class-validator';

import { EGroup } from '../EGroup';
import { PermissionName } from '../PermissionName';

export class RoleUpdateRequest {
    @IsOptional()
    @IsString()
    public description?: string;

    @IsString()
    @IsEnum(EGroup)
    public group: EGroup;

    @IsString()
    public name: string;

    @IsString()
    public title: string;

    @IsArray()
    @IsString({ each: true })
    public permissions?: PermissionName[];

    constructor(data?: Partial<RoleUpdateRequest>) {
        this.description = data && data.description;
        this.group = data && data.group;
        this.name = data && data.name;
        this.title = data && data.title;
        this.permissions = data && data.permissions;
    }
}
