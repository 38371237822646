import { PlaneRadiusVector } from './PlaneRadiusVector';
import { IPlaneLineCoordinates, IPlaneLinePoints } from './interfaces';

export class PlaneLine implements IPlaneLineCoordinates, IPlaneLinePoints {
    constructor(
        public start: PlaneRadiusVector,
        public end: PlaneRadiusVector,
        public isHighlighted: boolean = false
    ) {
    }

    public get x1(): number {
        return this.start.x;
    }

    public get y1(): number {
        return this.start.y;
    }

    public get x2(): number {
        return this.end.x;
    }

    public get y2(): number {
        return this.end.y;
    }

    public static createFromPoints(x1: number, y1: number, x2: number, y2: number, isHighlighted = false): PlaneLine {
        return new PlaneLine(new PlaneRadiusVector(x1, y1), new PlaneRadiusVector(x2, y2), isHighlighted);
    }
}
