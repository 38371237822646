import { IsOptional, IsString } from 'class-validator';

import { RestApiResponse } from '../common/RestApiResponse';

export class ContactUpdateResponse extends RestApiResponse {
    @IsString()
    @IsOptional()
    public readonly uuid?: string;

    @IsString()
    @IsOptional()
    public readonly contactPhone?: string;

    @IsString()
    @IsOptional()
    public readonly contactPerson?: string;

    constructor(data?: Partial<ContactUpdateResponse>) {
        super(data);

        this.uuid = data?.uuid;
        this.contactPerson = data?.contactPerson;
        this.contactPhone = data?.contactPhone;
    }
}
