import { BaseField } from '../taxonomy';

export class NomenclatureTypeChildParameter {
    public fieldName: string;
    public label: string;
    public formula: string;
    public baseField: BaseField;

    constructor(data?: Partial<NomenclatureTypeChildParameter>) {
        this.fieldName = data?.fieldName;
        this.label = data?.label;
        this.formula = data?.formula;
        this.baseField = data?.baseField;
    }
}
