import { StorageValue } from './storage-value';
import { CacheStorageAbstract } from './cache-storage-abstract';

export class CacheMemoryStorage extends CacheStorageAbstract {
    private data: { [key: string]: StorageValue } = {};

    public getItem(key: string): StorageValue {
        return this.data[key] ? this.data[key] : null;
    }

    public setItem(key: string, value: StorageValue): boolean {
        this.data[key] = value;
        return true;
    }

    public removeItem(key: string): void {
        delete this.data[key];
    }

    public clear(): void {
        this.data = {};
    }
}
