export enum ECacheTag {
    // Person
    person = 'cacheKeyPerson',

    // Taxonomy
    baseField = 'cacheKeyTaxonomyBaseField',
    reference = 'cacheKeyTaxonomyReference',
    referenceField = 'cacheKeyTaxonomyReferenceField',
    referenceItem = 'cacheKeyTaxonomyReferenceItem',

    // ContragentEntity
    contragent = 'cacheKeyContragent',

    // DealerEntity
    dealer = 'cacheKeyDealer',

    // OrderEntity
    order = 'cacheKeyOrder',

    // Nomenclature
    nomenclatureType = 'cacheKeyNomenclatureType',
    nomenclatureTypeField = 'cacheKeyNomenclatureTypeField',
    nomenclatureTypeChild = 'cacheKeyNomenclatureTypeChild',
    nomenclaturePosition = 'cacheKeyNomenclaturePosition',

    // Prices
    priceType = 'cacheKeyPriceType',
    priceValue = 'cacheKeyPriceValue',

    // Specifications
    specification = 'cacheKeySpecification',
    specificationOption = 'cacheKeySpecificationOption',
    specificationItem = 'cacheKeySpecificationItem',

    // Production Areas
    area = 'cacheKeyArea',
    areaTypes = 'cacheKeyAreaTypes',

    // Profile
    profile = 'profile',

    // Profile Catalog
    profileCatalog = 'profileCatalog',

    // Settings
    settings = 'settings',

    // Announcement
    announcement = 'announcement',
}
