/**
 * Created by optimistex on 16.06.17.
 */

export enum EStickerType {
    NO_PRINT = 0,
    PER_UNIT = 1,
    PER_SET = 2,
}

export type TStickerPrintType =
    'STICKER_TYPE_MAIN'             // главная этикетка
    | 'STICKER_TYPE_PARAMS'         // этикетка с параметрами
    | 'STICKER_TYPE_PROFILE'        // этикетка эскиза профиля
    | 'STICKER_TYPE_UNWRAP_PROFILE' // этикетка развёртки профиля
    | 'STICKER_TYPE_SUMMARY'        // сумарная этикетка со списком позиций без индивидуальной этикетки
    | 'STICKER_TYPE_PAID_ONLINE'
    | 'STICKER_TYPE_SERVICE_COMMENT'
    | 'STICKER_TYPE_PICKUP_ADDRESS'
    | 'STICKER_TYPE_BAR_CODE';

export const configStickers = {
    /** Значения типов этикеток на позицию. Определяет формирование этикеток для позиции заказа. */
    STICK_TYPE: {
        NO_PRINT: EStickerType.NO_PRINT,
        PER_UNIT: EStickerType.PER_UNIT,
        PER_SET: EStickerType.PER_SET,
    },

    /** Типы этикеток в печать. Определяет внешний вид этикетки при печати. */
    PRINT_TYPE: {
        MAIN: 'STICKER_TYPE_MAIN' as const,
        PARAMS: 'STICKER_TYPE_PARAMS' as const,
        PROFILE: 'STICKER_TYPE_PROFILE' as const,
        UNWRAP_PROFILE: 'STICKER_TYPE_UNWRAP_PROFILE' as const,
        SUMMARY: 'STICKER_TYPE_SUMMARY' as const,
        PAID_ONLINE: 'STICKER_TYPE_PAID_ONLINE' as const,
        SERVICE_COMMENT: 'STICKER_TYPE_SERVICE_COMMENT' as const,
        PICKUP_ADDRESS: 'STICKER_TYPE_PICKUP_ADDRESS' as const,
        BAR_CODE: 'STICKER_TYPE_BAR_CODE' as const,
    },

    /** Максимальная длина имен/значений параметров. При превышении параметры выносятся на отдельную этикетку */
    LIMITS: {
        MAX_LEN_NAMES: 30,
        MAX_LEN_VALUES: 15,
        MAX_LEN_ONE_VALUE: 18,
    },
};
