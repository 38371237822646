import { IsInt, IsOptional, IsString } from 'class-validator';

import { Announcement } from '../announcement';

export class AnnouncementUpdateRequest implements Pick<Announcement, 'id' | 'title' | 'message'> {
    @IsInt()
    @IsOptional()
    public readonly id: number;

    @IsString()
    public readonly title: string;

    @IsString()
    public readonly message: string;

    constructor(data?: Partial<AnnouncementUpdateRequest>) {
        this.id = data?.id;
        this.title = data?.title;
        this.message = data?.message;
    }
}
