import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
    RestApiResponse, UserGetInfoResponse, UserGetPermanentAuthTokenResponse, UserInfo, UserLoginInfo,
    UserLoginRequest, UserSetPasswordRequest,
} from '@ls-front/sharable';

import { ApiService } from '../_service/api.service';

import { GeneralService } from './general.service';
import { MessengerService } from './messenger.service';
import { WsApiService } from './ws-api.service';
import { AuthService } from './auth/auth.service';
import { UserSession } from './session/user-session';
import { SessionService } from './session/session.service';

/**
 * @deprecated use SessionService
 */
@Injectable({ providedIn: 'root' })
export class UserService {
    /**
     * @deprecated use SessionService
     */
    public static userSession: UserSession = new UserSession();

    constructor(private api: ApiService, private wsApi: WsApiService, private general: GeneralService, private msg: MessengerService,
                private router: Router, private authService: AuthService, private session: SessionService) {
        // Set error filter for ApiService
        this.api.errorAuthFilter = (response: HttpErrorResponse) => {
            // Логаут при ответе 401
            if (response.status === 401) {
                if (UserService.userSession.isLoggedIn()) {
                    this.msg.warning('Сервер требует повторную авторизацию!');
                }
                this.logout(true);
                return false;
            }
            return true;
        };

        this.session.userSession$.subscribe(userSession => UserService.userSession = userSession);
    }

    /**
     * @deprecated use SessionService
     */
    public static loginInfo(): UserLoginInfo {
        return UserService.userSession;
    }

    /**
     * Проверка прав доступа для текущего пользователя
     *
     * @param permissionName имя права (разрешения)
     * @deprecated use SessionService
     */
    public static can(permissionName: string | string[]): boolean {
        return UserService.userSession.can(permissionName);
    }

    /**
     * Авторизация пользователя на сервере.
     * В результате получаем токен авторизации дающий возможность использовать все API сервера
     *
     * @deprecated use AuthService.login
     */
    public login(request: Readonly<UserLoginRequest>): Observable<UserSession> {
        return this.authService.login(new UserLoginRequest(request)).pipe(
            filter(userSession => !!userSession.token)
        );
    }

    /**
     * Сброс авторизации пользователя
     *
     * @param withoutRequest не посылать запрос на сервер. позволяет избежать зацикливания
     * @deprecated use AuthService.logout
     */
    public logout(withoutRequest = false): void {
        this.authService.logout(withoutRequest).subscribe(() => {
            this.router.navigate(['/login']).catch(err => console.warn('UserService.logout:', err));
        });
    }

    /**
     * Получение обновленных данных профиля с сервера
     *
     * @returns promise
     */
    public loadLoginInfo(): Observable<UserInfo> {
        return this.api.post<UserGetInfoResponse>('/rest_api_v2/account/user-get-info').pipe(
            map(data => new UserGetInfoResponse(data).userInfo)
        );
    }

    public getUserPermanentAuthToken(): Observable<string> {
        return this.api.post<UserGetPermanentAuthTokenResponse>('/rest_api_v2/account/user-get-permanent-auth-token').pipe(
            map(data => new UserGetPermanentAuthTokenResponse(data).token)
        );
    }

    /**
     * Сохранение данных профиля на сервере
     *
     * @param data данные для сохранения
     */
    public setUserInfo(data: UserInfo): Observable<RestApiResponse> {
        return this.api.post('/rest_api_v2/account/user-set-info', data)
            .pipe(tap(() => {
                const userSession = new UserSession({
                    token: UserService.userSession.token,
                    group: UserService.userSession.group,
                    groupTitle: UserService.userSession.groupTitle,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    lastName: data.lastName,
                    roles: UserService.userSession.roles,
                    permissions: UserService.userSession.permissions,
                    areas: UserService.userSession.areas,
                });
                this.session.setUserSession(userSession);
            }));
    }

    /** Устанавливает новый пароль текущего пользователя */
    public setPassword(request: UserSetPasswordRequest): Observable<void> {
        return this.api.post('/rest_api_v2/account/user-set-password', request);
    }
}
