import { IsIn, IsOptional, ValidateNested } from 'class-validator';

import { CrudShowMode } from './CrudShowMode';
import { CrudPaginateRequest } from './CrudPaginateRequest';

export class CrudItemListRequest {
    @IsOptional()
    @IsIn([CrudShowMode.all, CrudShowMode.deleted, CrudShowMode.undeleted])
    public show?: CrudShowMode;

    @ValidateNested()
    public paginateInfo?: CrudPaginateRequest;

    constructor(data?: Partial<CrudItemListRequest>) {
        this.show = data?.show;
        this.paginateInfo = data?.paginateInfo && new CrudPaginateRequest(data.paginateInfo);
    }
}
