import { RestApiResponse } from '../../common/RestApiResponse';

import { Person } from './Person';

export class PersonGetResponse extends RestApiResponse {
    public person: Person;

    constructor(data?: Partial<PersonGetResponse>) {
        super(data);
        this.person = new Person(data?.person);
    }
}
