import { IsString } from 'class-validator';

import { CrudItemListRequest } from '../../crud';

export class TaxonomyReferenceItemGetListRequest extends CrudItemListRequest {
    @IsString()
    public filterItemsByReferenceUuid: string;

    constructor(data?: Partial<TaxonomyReferenceItemGetListRequest>) {
        super(data);
        this.filterItemsByReferenceUuid = data && data.filterItemsByReferenceUuid;
    }
}
