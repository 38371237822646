import { IsString } from 'class-validator';

export class TaxonomyReferenceItemGetRequest {
    @IsString()
    public uuid: string;

    constructor(data?: Partial<TaxonomyReferenceItemGetRequest>) {
        this.uuid = data && data.uuid;
    }
}
