import { IsEnum, IsNotEmpty, IsOptional, IsString, Matches, ValidateNested } from 'class-validator';

import { BaseFieldType } from '../BaseFieldType';
import { FieldOptions } from '../field-options/FieldOptions';
import { FieldOptionsFactory } from '../field-options/FieldOptionsFactory';

export interface TaxonomyBaseFieldUpdateRequestConstruction {
    name?: string;
    type?: BaseFieldType;
    options?: Partial<FieldOptions>;
    title?: string;
}

export class TaxonomyBaseFieldUpdateRequest {
    @IsString()
    @Matches(
        /^f_[a-zA-Z_0-9]+$/,
        { message: 'name должно содержать только буквы латинского алфавита, цифры и символы подчёркивания' }
    )
    public name: string;

    @IsString()
    @IsNotEmpty()
    public readonly title: string;

    @IsEnum(BaseFieldType)
    public readonly type: BaseFieldType;

    @IsOptional()
    @ValidateNested()
    public readonly options: FieldOptions;

    constructor(data?: TaxonomyBaseFieldUpdateRequestConstruction) {
        this.name = typeof data?.name === 'string'
            ? (data.name.startsWith('f_') ? data.name : 'f_' + data.name)
            : data?.name;
        this.type = data?.type;
        this.title = data?.title;
        this.options = FieldOptionsFactory.create(this.type, data?.options);
    }
}
