import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { EWebSocketMessageType, IWebSocketDataNotify, IWebSocketMessage } from '@ls-front/sharable';
import { environment } from '@ls-front/env';

import { CustomCacheService } from './custom-cache.service';
import { SessionService } from './session/session.service';

@Injectable({ providedIn: 'root' })
export class WsApiService {
    private wSocket: WebSocket;
    private isRetryActive = false;
    private authToken: string;

    private open$$ = new Subject<Event>();
    private data$$ = new Subject<IWebSocketMessage<any>>();
    private error$$ = new Subject<Event>();
    private close$$ = new Subject<CloseEvent>();

    private notify$$ = new Subject<IWebSocketDataNotify>();

    constructor(private cache: CustomCacheService, sessionService: SessionService) {
        this.listenSocket();

        sessionService.userSession$.subscribe(userSession => this.setAuthToken(userSession.token));
    }

    public get notify(): Observable<IWebSocketDataNotify> {
        return this.notify$$.asObservable().pipe(debounceTime(5000));
    }

    private setAuthToken(token: string = null): void {
        this.authToken = token;
        if (this.authToken) {
            this.connect();
        } else {
            this.disconnect();
        }
    }

    get isConnected(): boolean {
        return this.wSocket && this.wSocket.readyState === this.wSocket.OPEN;
    }

    private connect(): void {
        this.isRetryActive = true;
        if (this.isConnected) {
            return;
        }

        try {
            this.wSocket = new WebSocket(`${environment.hostWsApiUrl}?token=${this.authToken.replace('#', '::')}`);
            this.wSocket.onopen = evt => this.open$$.next(evt);
            this.wSocket.onmessage = evt => this.data$$.next(JSON.parse(evt.data));
            this.wSocket.onclose = evt => this.close$$.next(evt);
            this.wSocket.onerror = evt => this.error$$.next(evt);
        } catch (error) {
            console.log('reconnect websocket', error);
        }
    }

    private disconnect(): void {
        this.isRetryActive = false;
        if (this.isConnected) {
            this.wSocket.close();
            this.wSocket = null;
        }
    }

    private listenSocket(): void {
        this.data$$.subscribe(response => {
            switch (response.type) {
                case EWebSocketMessageType.NOTIFICATION:
                    this.notify$$.next(response.data); // to WebCallService
                    break;

                case EWebSocketMessageType.CONNECTION_PING:
                    this.sendData({ type: EWebSocketMessageType.CONNECTION_PONG });
                    break;
                default:
            }
        });

        this.notify$$.subscribe(data =>
            data.tags.forEach((tag: string) => this.cache.removeTag(tag))
        );
        this.open$$.subscribe(() => console.log('ws connected'));

        this.close$$.pipe( // auto reconnect
            debounceTime(3000),
            filter(() => !this.isConnected && this.isRetryActive)
        ).subscribe(() => this.connect());
    }

    public sendData(data: IWebSocketMessage<any>): void {
        if (!this.isConnected) {
            this.open$$.pipe(take(1)).subscribe(() => this.sendData(data));
        }

        try {
            this.wSocket.send(JSON.stringify(data));
            // console.log('socket %cout', 'color: red; font-weight: bold', data);
        } catch (error) {
            console.error('Error in sendData', error);
        }
    }
}
