import { EventEmitter, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    public changed = new EventEmitter<string>();
    private currentTheme = 'blue';

    public get(): string {
        return this.currentTheme;
    }

    public set(newThemeName: string): void {
        this.currentTheme = newThemeName;
        this.changed.emit(this.currentTheme);
    }

    public check(themeName: string): boolean {
        return this.currentTheme === themeName;
    }
}
