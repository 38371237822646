import { PlaneLineEx, PlaneRadiusVector } from '../mathematic-plane';

import { IProfileLine } from './IProfileLine';

export class PlaneProfileLine extends PlaneLineEx {
    constructor(
        public readonly start: PlaneRadiusVector,
        public readonly end: PlaneRadiusVector,
        public isHighlighted: boolean = false,
        public readonly paintLineDistance: number,
        public readonly line: IProfileLine
    ) {
        super(start, end, isHighlighted, paintLineDistance);
    }

    public get angle(): number {
        return this.line.alpha;
    }

    public get label2dLine(): string {
        return this.line.length !== this.line.length2 ? this.line.length + '/' + this.line.length2 : this.line.length.toString();
    }

    public get label3dLineBack(): string {
        return this.line.length2.toString();
    }

    public get label3dLineFront(): string {
        return this.line.length.toString();
    }

    public get labelAngle(): string {
        return Math.abs(this.line.alpha) + '°';
    }
}
