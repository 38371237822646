import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

import { SettingsInfo } from './SettingsInfo';

export class Setting {
    @IsString()
    @IsNotEmpty()
    public name: keyof SettingsInfo;

    @IsOptional()
    @IsString()
    public value?: string;

    constructor(data?: Partial<Setting>) {
        this.name = data?.name;
        this.value = data?.value;
    }
}
