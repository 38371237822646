import { IsInt, IsOptional, Min, ValidateNested } from 'class-validator';

import { SortOptions } from './SortOptions';

export class CrudPaginateRequest {
    @IsOptional()
    @IsInt()
    @Min(1)
    public page?: number;

    @IsOptional()
    @IsInt()
    @Min(1)
    public limit?: number; // lines per page

    @ValidateNested()
    public sort?: SortOptions;

    constructor(data: Partial<CrudPaginateRequest>) {
        this.page = data && data.page;
        this.limit = data && data.limit;
        this.sort = SortOptions.create(data && data.sort);
    }
}
