import { PlaneLine } from './PlaneLine';
import { ILineEquation } from './interfaces';
import { PlaneRadiusVector } from './PlaneRadiusVector';
import { MathPlane } from './MathPlane';

export class PlaneLineEx extends PlaneLine {
    private internalLineEquation: ILineEquation;
    private internalStartOrthogonalLineEquation: ILineEquation;
    private internalEndOrthogonalLineEquation: ILineEquation;
    private internalTopPaintLineEquation: ILineEquation;
    private internalTopPaintLineStart: PlaneRadiusVector;
    private internalTopPaintLineEnd: PlaneRadiusVector;
    private internalBottomPaintLineEquation: ILineEquation;
    private internalBottomPaintLineStart: PlaneRadiusVector;
    private internalBottomPaintLineEnd: PlaneRadiusVector;

    constructor(
        public start: PlaneRadiusVector,
        public end: PlaneRadiusVector,
        public isHighlighted: boolean = false,
        public paintLineDistance: number
    ) {
        super(start, end, isHighlighted);
    }

    public get lineEquation(): ILineEquation {
        if (!this.internalLineEquation) {
            this.internalLineEquation = MathPlane.lineEquation(this);
        }
        return this.internalLineEquation;
    }

    public get startOrthogonalLineEquation(): ILineEquation {
        if (!this.internalStartOrthogonalLineEquation) {
            this.internalStartOrthogonalLineEquation = MathPlane.orthogonalLineEquation(this.lineEquation, this.start);
        }
        return this.internalStartOrthogonalLineEquation;
    }

    public get endOrthogonalLineEquation(): ILineEquation {
        if (!this.internalEndOrthogonalLineEquation) {
            this.internalEndOrthogonalLineEquation = MathPlane.orthogonalLineEquation(this.lineEquation, this.end);
        }
        return this.internalEndOrthogonalLineEquation;
    }

    public get topPaintLineEquation(): ILineEquation {
        if (!this.internalTopPaintLineEquation) {
            this.internalTopPaintLineEquation = MathPlane.parallelLineEquation(this.lineEquation, this.paintLineDistance);
        }
        return this.internalTopPaintLineEquation;
    }

    public get topPaintLineStart(): PlaneRadiusVector {
        if (!this.internalTopPaintLineStart) {
            this.internalTopPaintLineStart = MathPlane.intersectPoint(this.topPaintLineEquation, this.startOrthogonalLineEquation);
        }
        return this.internalTopPaintLineStart;
    }

    public get topPaintLineEnd(): PlaneRadiusVector {
        if (!this.internalTopPaintLineEnd) {
            this.internalTopPaintLineEnd = MathPlane.intersectPoint(this.topPaintLineEquation, this.endOrthogonalLineEquation);
        }
        return this.internalTopPaintLineEnd;
    }

    public get bottomPaintLineEquation(): ILineEquation {
        if (!this.internalBottomPaintLineEquation) {
            this.internalBottomPaintLineEquation = MathPlane.parallelLineEquation(this.lineEquation, -this.paintLineDistance);
        }
        return this.internalBottomPaintLineEquation;
    }

    public get bottomPaintLineStart(): PlaneRadiusVector {
        if (!this.internalBottomPaintLineStart) {
            this.internalBottomPaintLineStart = MathPlane.intersectPoint(this.bottomPaintLineEquation, this.startOrthogonalLineEquation);
        }
        return this.internalBottomPaintLineStart;
    }

    public get bottomPaintLineEnd(): PlaneRadiusVector {
        if (!this.internalBottomPaintLineEnd) {
            this.internalBottomPaintLineEnd = MathPlane.intersectPoint(this.bottomPaintLineEquation, this.endOrthogonalLineEquation);
        }
        return this.internalBottomPaintLineEnd;
    }

    public asString(): { [key: string]: string } {
        return {
            lineEquation: MathPlane.lineEquationToString(this.lineEquation),
            startOrthogonalLineEquation: MathPlane.lineEquationToString(this.startOrthogonalLineEquation),
            endOrthogonalLineEquation: MathPlane.lineEquationToString(this.endOrthogonalLineEquation),
            topPaintLineEquation: MathPlane.lineEquationToString(this.topPaintLineEquation),
            topPaintLineStart: MathPlane.pointToString(this.topPaintLineStart),
            topPaintLineEnd: MathPlane.pointToString(this.topPaintLineEnd),
            bottomPaintLineEquation: MathPlane.lineEquationToString(this.bottomPaintLineEquation),
            bottomPaintLineStart: MathPlane.pointToString(this.bottomPaintLineStart),
            bottomPaintLineEnd: MathPlane.pointToString(this.bottomPaintLineEnd),
        };
    }
}
