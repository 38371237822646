import { BaseFieldType } from '../BaseFieldType';

import { FieldOptions } from './FieldOptions';
import { FieldOptionsImage } from './FieldOptionsImage';
import { FieldOptionsString } from './FieldOptionsString';
import { FieldOptionsNumber } from './FieldOptionsNumber';
import { FieldOptionsProfile } from './FieldOptionsProfile';
import { FieldOptionsReference } from './FieldOptionsReference';

export class FieldOptionsFactory {
    public static create(type: BaseFieldType, options?: Partial<FieldOptions>): FieldOptions | undefined {
        switch (type) {
            case BaseFieldType.FIELD_IMAGE:
                return new FieldOptionsImage(options as Partial<FieldOptionsImage>);
            case BaseFieldType.FIELD_NUMBER:
                return new FieldOptionsNumber(options as Partial<FieldOptionsNumber>);
            case BaseFieldType.FIELD_PROFILE:
                return new FieldOptionsProfile(options as Partial<FieldOptionsProfile>);
            case BaseFieldType.FIELD_REFERENCE:
                return new FieldOptionsReference(options as Partial<FieldOptionsReference>);
            case BaseFieldType.FIELD_STRING:
                return new FieldOptionsString(options as Partial<FieldOptionsString>);
        }
        return undefined;
    }
}
