import { IsEnum, IsInt, ValidateIf, isDefined } from 'class-validator';

import { EGroup } from '../../EGroup';

import { AbstractPersonRequest } from './AbstractPersonRequest';

export class PersonCreateRequest extends AbstractPersonRequest {
    @IsEnum(EGroup)
    public group: EGroup;

    @ValidateIf((o: PersonCreateRequest, value: string) => isDefined(value) || (o.group === EGroup.DEALER))
    @IsInt()
    public dealerId: number;

    constructor(data?: Partial<PersonCreateRequest>) {
        super(data);
        this.group = data?.group;
        this.dealerId = data?.group === EGroup.DEALER ? data?.dealerId : undefined;
    }
}
