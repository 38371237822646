import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class TaxonomyReferenceUpdateRequest {
    @IsOptional()
    @IsString()
    @IsNotEmpty()
    public uuid?: string;

    @IsString()
    @IsNotEmpty()
    public name: string;

    @IsOptional()
    @IsString()
    public description?: string;

    constructor(data?: Partial<TaxonomyReferenceUpdateRequest>) {
        this.uuid = data?.uuid;
        this.name = data?.name;
        this.description = data?.description;
    }
}
