import { IsInt, IsNotEmpty, IsString } from 'class-validator';

export class AreaAddNomenclatureTypeRequest {
    @IsNotEmpty()
    @IsString()
    public nomTypeUuid: string;

    @IsInt()
    public areaId: number;

    constructor(data?: Partial<AreaAddNomenclatureTypeRequest>) {
        this.nomTypeUuid = data?.nomTypeUuid;
        this.areaId = data?.areaId;
    }
}
