import { maxBy, minBy } from 'lodash';

import {
    ILineEquation, IPlaneFrame, IPlanePoint, ITextSize, MathPlane, PlaneArc, PlaneFrame, PlaneLabel, PlaneLine, PlaneLineEx, PlaneRadiusVector,
} from './mathematic-plane';
import { Profile } from './profile-items/Profile';
import { IProfileLine } from './profile-items/IProfileLine';
import { PlaneProfileLine } from './profile-items/PlaneProfileLine';
import { PlaneProfileLabel } from './profile-items/PlaneProfileLabel';
import { PlaneRadiusVectorTransform } from './mathematic-plane/PlaneRadiusVectorTransform';
import { IClosestLine } from './IClosestLine';
import { TProfileEdgeCrease } from './profile-items/TProfileEdgeCrease';

export type TBlueprintItemEx = PlaneLineEx | PlaneArc;
type TBlueprintList = PlaneLine | PlaneArc;

type ProfileRenderEngineTarget = '2d' | '3d';

export class ProfileRenderEngine {
    // Options
    public radiusCreaseInternal = 2;
    public radiusCreaseEdge = 2.5;
    public paintLineDistance = 3;
    public maxAngleToMakeArc = MathPlane.toRadians(26);

    // Data
    public blueprintLines3dBack: TBlueprintItemEx[] = [];
    public blueprintLines: TBlueprintItemEx[] = [];
    public topPaintLines: TBlueprintList[] = [];
    public bottomPaintLines: TBlueprintList[] = [];
    public labels3dBack: PlaneProfileLabel[] = [];
    public labels: PlaneProfileLabel[] = [];
    public getTextSize: (text: string) => ITextSize;
    public viewDimensions: IPlaneFrame;

    constructor(private readonly profile: Profile, private readonly renderTransform: PlaneRadiusVectorTransform,
                private target: ProfileRenderEngineTarget = '2d') {
    }

    private static createTextFrame(trackLineEquation: ILineEquation, position: IPlanePoint, textSize: ITextSize): IPlaneFrame {
        const middlePointTop = MathPlane.getPointOnLine(trackLineEquation, position, textSize.height / 2);
        const middlePointBottom = MathPlane.getPointOnLine(trackLineEquation, position, -textSize.height / 2);
        const orthogonalTopEquation = MathPlane.orthogonalLineEquation(trackLineEquation, middlePointTop);
        const orthogonalBottomEquation = MathPlane.orthogonalLineEquation(trackLineEquation, middlePointBottom);
        const parallelLeftEquation = MathPlane.parallelLineEquation(trackLineEquation, textSize.width / 2);
        const parallelRightEquation = MathPlane.parallelLineEquation(trackLineEquation, -textSize.width / 2);

        return new PlaneFrame(
            MathPlane.intersectPoint(orthogonalTopEquation, parallelLeftEquation),
            MathPlane.intersectPoint(orthogonalTopEquation, parallelRightEquation),
            MathPlane.intersectPoint(orthogonalBottomEquation, parallelRightEquation),
            MathPlane.intersectPoint(orthogonalBottomEquation, parallelLeftEquation)
        );
    }

    private static createViewDimensions(center: IPlanePoint, viewWidth: number, viewHeight: number): IPlaneFrame {
        return new PlaneFrame(
            new PlaneRadiusVector(center.x - viewWidth / 2, center.y - viewHeight / 2),
            new PlaneRadiusVector(center.x + viewWidth / 2, center.y - viewHeight / 2),
            new PlaneRadiusVector(center.x + viewWidth / 2, center.y + viewHeight / 2),
            new PlaneRadiusVector(center.x - viewWidth / 2, center.y + viewHeight / 2)
        );
    }

    private static internalCheckCrossings(line: PlaneLineEx, lines: PlaneProfileLine[]): boolean {
        for (let i = 0, len = lines.length; i < len - 1; i++) {
            if (MathPlane.intersectsByPoints(line.start, line.end, lines[i].start, lines[i].end)) {
                return true;
            }
        }
        return false;
    }

    private static lineIntersectsTextFrame(line: PlaneLine, textFrame: IPlaneFrame): boolean {
        return MathPlane.intersects(line, textFrame.lineTop) || MathPlane.intersects(line, textFrame.lineRight)
            || MathPlane.intersects(line, textFrame.lineBottom) || MathPlane.intersects(line, textFrame.lineLeft);
    }

    private static createAngleLabel(
        line: PlaneProfileLine, getTextSize: (text: string) => ITextSize, checkLabelCrosses: (label: PlaneLabel) => boolean,
        viewDimensions: IPlaneFrame
    ): PlaneProfileLabel {
        let labelAngle = MathPlane.toDegrees(MathPlane.acuteAngleToHorizontal(line));
        labelAngle += line.angle / 2;
        if (Math.abs(labelAngle) >= 90 && Math.abs(labelAngle) < 270) {
            labelAngle += 180;
        }
        const label = new PlaneProfileLabel(line, line.labelAngle, line.start, labelAngle, true);
        let textSize = getTextSize(label.text);
        // noinspection JSSuspiciousNameCombination
        textSize = { width: textSize.height, height: textSize.width };
        const bisectorEquation = MathPlane.rotateLineEquation(line.lineEquation, line.start, MathPlane.toRadians(line.angle / 2));
        const distances = [
            ...MathPlane.rangeWithOpposites(-17, -5, 1),
            ...MathPlane.rangeWithOpposites(-25, -19, 2),
            ...MathPlane.rangeWithOpposites(-27, -60, 3),
        ];
        const viewDimensionsPolygon = viewDimensions ? ProfileRenderEngine.polygonFromFrame(viewDimensions) : [];
        mainLoop: for (const distance of distances) {
            const angleStep = Math.ceil(Math.abs(distance) / 10);
            for (const trackLineAngle of MathPlane.rangeWithOpposites(0, -45, angleStep)) {
                const trackLineEquation = trackLineAngle
                    ? MathPlane.rotateLineEquation(bisectorEquation, line.start, MathPlane.toRadians(trackLineAngle))
                    : bisectorEquation;

                label.position = MathPlane.getPointOnLine(trackLineEquation, line.start, distance);
                label.textFrame = ProfileRenderEngine.createTextFrame(trackLineEquation, label.position, textSize);
                if (Math.abs(distance) > 15) {
                    const trackDistance = (distance > 0 ? -1 : 1) * textSize.height / 2;
                    label.trackLine = new PlaneLine(line.start, MathPlane.getPointOnLine(trackLineEquation, label.position, trackDistance));
                }
                const isInsideView = viewDimensions ? ProfileRenderEngine.isLabelInsideView(label, viewDimensionsPolygon) : true;
                if (!isInsideView) {
                    continue;
                }
                if (!checkLabelCrosses(label)) {
                    label.angle += trackLineAngle;
                    break mainLoop;
                }
            }
        }

        return label;
    }

    private static checkLabelCrossLines(label: PlaneLabel, labelList: PlaneProfileLabel[], lineList: TBlueprintList[]): boolean {
        for (const line of lineList) {
            if ((line instanceof PlaneLine) && ProfileRenderEngine.lineIntersectsTextFrame(line, label.textFrame)) {
                return true;
            }
        }
        const labelLines = [label.textFrame.lineTop, label.textFrame.lineRight, label.textFrame.lineBottom, label.textFrame.lineLeft];
        if (label.trackLine) {
            labelLines.push(label.trackLine);
        }
        for (const xLabel of labelList) {
            const xLabelLines = [
                xLabel.textFrame.lineTop, xLabel.textFrame.lineRight, xLabel.textFrame.lineBottom, xLabel.textFrame.lineLeft,
            ];
            // if (xLabel.trackLine) {
            //     xLabelLines.push(xLabel.trackLine);
            // }
            for (const l1 of xLabelLines) {
                for (const l2 of labelLines) {
                    if (MathPlane.intersects(l1, l2)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private static polygonFromFrame(frame: IPlaneFrame): IPlanePoint[] {
        return [
            frame.lineTop.start, frame.lineTop.end, frame.lineRight.start, frame.lineRight.end,
            frame.lineBottom.start, frame.lineBottom.end, frame.lineLeft.start, frame.lineLeft.end,
        ];
    }

    private static isLabelInsideView(label: PlaneLabel, viewDimensionPolygon: IPlanePoint[]): boolean {
        return label.textFrame
            ? MathPlane.isPointInsidePolygon(label.textFrame.lineTop.start, viewDimensionPolygon)
            && MathPlane.isPointInsidePolygon(label.textFrame.lineRight.start, viewDimensionPolygon)
            && MathPlane.isPointInsidePolygon(label.textFrame.lineBottom.start, viewDimensionPolygon)
            && MathPlane.isPointInsidePolygon(label.textFrame.lineLeft.start, viewDimensionPolygon)
            : MathPlane.isPointInsidePolygon(label.position, viewDimensionPolygon);
    }

    private buildLabels(
        planeProfileLines: PlaneProfileLine[], lineListForCheckingLabelCrosses: TBlueprintList[], getTextSize: (text: string) => ITextSize,
        viewDimensions: IPlaneFrame, useLength2 = false
    ): PlaneProfileLabel[] {
        const labelList: PlaneProfileLabel[] = [];
        const firstLine = planeProfileLines.length ? planeProfileLines[0] : null;
        planeProfileLines = planeProfileLines.sort((a, b) => a.line.length - b.line.length);
        planeProfileLines.forEach(line => {
            if (line !== firstLine && line.angle && !useLength2) {
                labelList.push(ProfileRenderEngine.createAngleLabel(line, getTextSize,
                    label => ProfileRenderEngine.checkLabelCrossLines(label, labelList, lineListForCheckingLabelCrosses),
                    viewDimensions
                ));
            }
            // });
            // planeProfileLines.forEach((line) => {
            if (!useLength2 || (line.line.length !== line.line.length2)) {
                labelList.push(this.createLineLabel(line, getTextSize,
                    label => ProfileRenderEngine.checkLabelCrossLines(label, labelList, lineListForCheckingLabelCrosses), useLength2
                ));
            }
        });
        return labelList;
    }

    private createLineLabel(
        line: PlaneProfileLine, getTextSize: (text: string) => ITextSize, checkLabelCrosses: (label: PlaneLabel) => boolean, useLength2 = false
    ): PlaneProfileLabel {
        let labelAngle = MathPlane.toDegrees(MathPlane.acuteAngleToHorizontal(line));
        if (Math.abs(labelAngle) >= 90 && Math.abs(labelAngle) < 270) {
            labelAngle += 180;
        }

        const lineMaxDistance = line.start.lengthTo(line.end) / 2;

        const lineDistances = MathPlane.rangeWithOpposites(0, lineMaxDistance - 2, 1);
        const centerPoint = line.start.add(line.end).divide(2);
        const labelText = this.target === '2d' ? line.label2dLine : (useLength2 ? line.label3dLineBack : line.label3dLineFront);
        const label = new PlaneProfileLabel(line, labelText, centerPoint, labelAngle, false);

        const distances = [
            ...MathPlane.rangeWithOpposites(0, -10, 1),
            ...MathPlane.rangeWithOpposites(-11, -60, 2),
        ];
        mainLoop: for (const distance of distances) {
            for (const lineDistance of lineDistances) {
                const startPoint = MathPlane.getPointOnLine(line.lineEquation, centerPoint, lineDistance);
                const textSize = getTextSize(label.text);
                const trackLineEquation = MathPlane.orthogonalLineEquation(line.lineEquation, startPoint);

                label.position = MathPlane.getPointOnLine(trackLineEquation, startPoint, distance);
                label.textFrame = ProfileRenderEngine.createTextFrame(trackLineEquation, label.position, textSize);
                if (Math.abs(distance) > 5) {
                    const trackDistance = (distance > 0 ? -1 : 1) * textSize.height / 2;
                    label.trackLine = new PlaneLine(startPoint, MathPlane.getPointOnLine(trackLineEquation, label.position, trackDistance));
                }
                if (!checkLabelCrosses(label)) {
                    break mainLoop;
                }
            }
        }

        return label;
    }

    private findPaintLinesIntersection(line1: PlaneLineEx, line2: PlaneLineEx, side: 'top' | 'bottom'): PlaneRadiusVector {
        if (!line1 || !line2) {
            return null;
        }
        const acuteAngle = MathPlane.acuteAngle(line1.start, line1.end, line2.end);
        if (Math.abs(Math.PI - Math.abs(acuteAngle)) < 0.0001) {
            return null;
        }
        const isInternalPaintLines = side === 'top'
            ? line1.start.lengthTo(line2.end) > line1.topPaintLineStart.lengthTo(line2.topPaintLineEnd)
            : line1.start.lengthTo(line2.end) > line1.bottomPaintLineStart.lengthTo(line2.bottomPaintLineEnd);
        if (!isInternalPaintLines && Math.abs(acuteAngle) <= this.maxAngleToMakeArc) {
            return null;
        }
        return side === 'top'
            ? MathPlane.intersectPoint(line1.topPaintLineEquation, line2.topPaintLineEquation)
            : MathPlane.intersectPoint(line1.bottomPaintLineEquation, line2.bottomPaintLineEquation);
    }

    public getPlaneProfileLines(): PlaneProfileLine[] {
        return this.blueprintLines.filter(item => item instanceof PlaneProfileLine) as PlaneProfileLine[];
    }

    public getPlaneProfileLines3dBack(): PlaneProfileLine[] {
        return this.blueprintLines3dBack.filter(item => item instanceof PlaneProfileLine) as PlaneProfileLine[];
    }

    public exportProfile(): string {
        return this.profile.toString();
    }

    public profileLengthTotal(): number {
        return this.profile.lengthTotal();
    }

    public setEdgeCreaseSize(sizeMM: number): void {
        this.profile.edgeCreaseSizeMM = sizeMM;
    }

    public createProfileLine(pointStart: IPlanePoint, pointEnd: IPlanePoint, maxLength: number): PlaneProfileLine {
        const line: IProfileLine = { alpha: null, length: null, length2: null, direction: null };

        const lineStart = PlaneRadiusVector.from(pointStart);
        const lineEnd = PlaneRadiusVector.from(pointEnd);
        const bluePrintLines = this.getPlaneProfileLines();
        let prevStart: PlaneRadiusVector, prevEnd: PlaneRadiusVector;
        if (bluePrintLines.length === 0) {
            prevStart = new PlaneRadiusVector(10, 0);
            prevEnd = new PlaneRadiusVector(0, 0);
            line.alpha = Math.round(MathPlane.acuteAngle(prevStart, prevEnd, lineEnd.delta(lineStart)) / Math.PI * 36) * 5;
            line.length = Math.round(lineStart.delta(lineEnd).length() / 5) * 5;
            line.direction = prevEnd.delta(prevStart).multiplyToVector(lineEnd.delta(lineStart)) >= 0 ? 1 : -1;
        } else {
            prevStart = bluePrintLines[bluePrintLines.length - 1].start;
            prevEnd = bluePrintLines[bluePrintLines.length - 1].end;
            line.alpha = Math.round(MathPlane.acuteAngle(prevStart, prevEnd, lineEnd) / Math.PI * 36) * 5;
            line.length = Math.round(prevEnd.delta(lineEnd).length() / 5) * 5;
            line.direction = prevEnd.delta(prevStart).multiplyToVector(lineEnd.delta(prevStart)) >= 0 ? 1 : -1;
        }
        if (line.length > maxLength) {
            line.length = maxLength;
        }
        line.length2 = line.length;
        line.alpha *= line.direction;
        const totalAlpha = -line.alpha + bluePrintLines.reduce((sum, l) => sum + 180 - l.angle, 0);
        let start: PlaneRadiusVector;
        if (line.alpha === 0 && bluePrintLines.length) {
            const v = prevEnd.delta(prevStart).normal().multiply(4);
            start = prevEnd.add(new PlaneRadiusVector(v.y, -v.x).multiply(-line.direction));
        } else {
            start = bluePrintLines.length ? prevEnd : lineStart;
        }
        const end = start.addLength(totalAlpha, line.length);
        return new PlaneProfileLine(start, end, false, this.paintLineDistance, line);
    }

    public findLineClosestToPoint(point: IPlanePoint): IClosestLine {
        let foundLine: PlaneProfileLine = null, foundLineDistance: number = null;
        const label = this.labels.find(l => l.isAngleLabel && MathPlane.isPointInsidePolygon(point, ProfileRenderEngine.polygonFromFrame(l.textFrame)));
        if (label) {
            foundLine = label.parentLine;
            foundLineDistance = 1;
        } else {
            this.getPlaneProfileLines().forEach(line => {
                const s = MathPlane.getDistanceBetweenPointAndLine(point, line.startOrthogonalLineEquation);
                const e = MathPlane.getDistanceBetweenPointAndLine(point, line.endOrthogonalLineEquation);
                if (((s < 0 && e > 0) || (s > 0 && e < 0))) {
                    const distance = Math.abs(MathPlane.getDistanceBetweenPointAndLine(point, line.lineEquation));
                    if (!foundLine || foundLineDistance > distance) {
                        foundLine = line;
                        foundLineDistance = distance;
                    }
                }
            });
        }
        return foundLine ? {
            line: foundLine,
            distance: foundLineDistance,
            isFirstLine: this.profile.lines.findIndex(l => l === foundLine.line) === 0,
        } : null;
    }

    public addProfileLine(profileLine: PlaneProfileLine): void {
        const line: IProfileLine = {
            alpha: profileLine.line.alpha,
            length: profileLine.line.length,
            length2: profileLine.line.length2,
            direction: profileLine.line.direction,
        };
        this.profile.lines.push(line);
        this.blueprintLines.push(profileLine);
    }

    public checkCrossings(line: PlaneLineEx): boolean {
        return ProfileRenderEngine.internalCheckCrossings(line, this.getPlaneProfileLines());
    }

    public fullCheckCrossingLines(): boolean {
        const lines = this.getPlaneProfileLines();
        for (const line of lines) {
            if (ProfileRenderEngine.internalCheckCrossings(line, lines)) {
                return true;
            }
        }
        return false;
    }

    public prepareProfileToRender(): void {
        this.blueprintLines = this.buildBlueprintItems(
            -this.renderTransform.rotateAngle,
            this.renderTransform.scale,
            this.renderTransform.apply(new PlaneRadiusVector(0, 0)),
            true
        );
        if (this.target === '3d') {
            this.blueprintLines3dBack = this.buildBlueprintItems(
                -this.renderTransform.rotateAngle,
                this.renderTransform.scale,
                this.renderTransform.apply(new PlaneRadiusVector(0, 0)),
                true, false, true
            );
        }
    }

    public prepareProfileToLiveRender(startPoint: IPlanePoint): void {
        this.blueprintLines = this.buildBlueprintItems(0, 1, startPoint, false);
    }

    public prepareTopPaintToRender(): void {
        this.topPaintLines = this.buildPaintLines('top', this.blueprintLines);
    }

    public prepareBottomPaintToRender(): void {
        this.bottomPaintLines = this.buildPaintLines('bottom', this.blueprintLines);
    }

    public prepareLabelsToRender(): void {
        this.labels = this.buildLabels(
            this.getPlaneProfileLines(),
            [...this.blueprintLines, ...this.topPaintLines, ...this.bottomPaintLines],
            text => this.calculateTextSize(text),
            this.viewDimensions
        );
        if (this.target === '3d') {
            this.labels3dBack = this.buildLabels(
                this.getPlaneProfileLines3dBack(),
                [...this.blueprintLines3dBack],
                text => this.calculateTextSize(text),
                this.viewDimensions, true
            );
        }
    }

    public adjustView(viewTransform: PlaneRadiusVectorTransform, viewWidth: number, viewHeight: number, paddingSize: number): void {
        const startPoint = viewTransform.apply(new PlaneRadiusVector(0, 0));
        const extremePoints = this.buildBlueprintItems(-viewTransform.rotateAngle, 1, startPoint, true, true).reduce(
            (points: { minX: number; minY: number; maxX: number; maxY: number }, item) => (item instanceof PlaneLine ? {
                minX: Math.min(points.minX, item.start.x, item.end.x), minY: Math.min(points.minY, item.start.y, item.end.y),
                maxX: Math.max(points.maxX, item.start.x, item.end.x), maxY: Math.max(points.maxY, item.start.y, item.end.y),
            } : points),
            { minX: startPoint.x, minY: startPoint.y, maxX: startPoint.x, maxY: startPoint.y }
        );
        const pointMin = new PlaneRadiusVector(extremePoints.minX, extremePoints.minY);
        const pointMax = new PlaneRadiusVector(extremePoints.maxX, extremePoints.maxY);
        const center = pointMin.add(pointMax).divide(2);
        this.renderTransform.center = viewTransform.center;
        this.renderTransform.translate = new PlaneRadiusVector(viewWidth / 2, viewHeight / 2).delta(center);
        this.renderTransform.rotateAngle = viewTransform.rotateAngle;
        this.renderTransform.scale = Math
            .min((viewWidth - paddingSize) / (pointMax.x - pointMin.x), (viewHeight - paddingSize) / (pointMax.y - pointMin.y));
        this.viewDimensions = ProfileRenderEngine.createViewDimensions(viewTransform.center, viewWidth, viewHeight);
    }

    private calculateTextSize(text: string): ITextSize {
        return this.getTextSize ? this.getTextSize(text) : { width: 20, height: 20 };
    }

    private getLineLength(length: number): number {
        const min = minBy(this.profile.lines, 'length');
        const max = maxBy(this.profile.lines, 'length');
        const coefficient = Math.max(4, Math.min(10, (+min.length * 60) / +max.length)); // between 4 and 10 (10 - no scale)
        return Math.pow(coefficient, Math.log10(length));
    }

    private buildBlueprintItems(angle: number, scale: number, startPoint: IPlanePoint, useScaling: boolean, forAdjustView = false,
                                useLength2 = false): TBlueprintItemEx[] {
        const blueprintLines: TBlueprintItemEx[] = [];
        let pos = PlaneRadiusVector.from(startPoint);
        let lineStart: PlaneRadiusVector, lineEnd: PlaneRadiusVector;
        let profileLine: PlaneProfileLine;
        this.profile.lines.forEach((line, index) => {
            if (line.alpha === 0 && index !== 0 && !forAdjustView) {
                const tempEnd = pos.addLength(angle + line.direction * 90, this.radiusCreaseInternal * 2);
                const center = pos.add(tempEnd).divide(2);
                const add = -line.direction * Math.PI / 2;
                blueprintLines.push(new PlaneArc(
                    center, this.radiusCreaseInternal,
                    angle * Math.PI / 180 + add, angle * Math.PI / 180 - add,
                    line.direction === -1
                ));
                pos = tempEnd;
            }
            lineStart = pos;
            angle += index === 0 ? -line.alpha : (180 - line.alpha);
            const lineLength = useLength2 ? line.length2 : line.length;
            if (useScaling) {
                pos = pos.addLength(angle, this.getLineLength(lineLength) * scale);
            } else {
                pos = pos.addLength(angle, lineLength);
            }
            lineEnd = pos;
            profileLine = new PlaneProfileLine(lineStart, lineEnd, false, this.paintLineDistance, line);
            if (index === 0 && this.profile.creaseLeft !== 0) {
                blueprintLines.push(...this.buildBlueprintEdgeItems(
                    profileLine, this.profile.creaseLeft, angle, scale, useScaling, 'left')
                );
            }
            blueprintLines.push(profileLine);
        });
        if (profileLine && this.profile.creaseRight !== 0) {
            blueprintLines.push(...this.buildBlueprintEdgeItems(
                profileLine, this.profile.creaseRight, angle - 180, scale, useScaling, 'right')
            );
        }
        return blueprintLines;
    }

    private buildBlueprintEdgeItems(profileLine: PlaneProfileLine, crease: TProfileEdgeCrease,
                                    angle: number, scale: number, useScaling: boolean, side: 'left' | 'right'): TBlueprintItemEx[] {
        const creaseLinePoint1 = MathPlane.intersectPoint(
            side === 'left' ? profileLine.startOrthogonalLineEquation : profileLine.endOrthogonalLineEquation,
            MathPlane.parallelLineEquation(profileLine.lineEquation, crease === -1
                ? -this.radiusCreaseEdge * 2 : this.radiusCreaseEdge * 2)
        );
        const edgeCreaseSize = useScaling ? this.getLineLength(this.profile.edgeCreaseSizeMM) : this.profile.edgeCreaseSizeMM;
        const creaseLinePoint2 = creaseLinePoint1
            .addLength(angle, edgeCreaseSize * scale);
        const arcCenter = creaseLinePoint1.add(side === 'left' ? profileLine.start : profileLine.end).divide(2);

        const creaseLineStart = side === 'left' ? creaseLinePoint2 : creaseLinePoint1;
        const creaseLineEnd = side === 'left' ? creaseLinePoint1 : creaseLinePoint2;
        const creaseLine = new PlaneLineEx(
            creaseLineStart, creaseLineEnd, profileLine.isHighlighted, this.paintLineDistance
        );
        const creaseArc = crease === -1
            ? new PlaneArc(
                arcCenter, this.radiusCreaseEdge,
                (angle + 90) * Math.PI / 180, (angle - 90) * Math.PI / 180, false
            )
            : new PlaneArc(
                arcCenter, this.radiusCreaseEdge,
                (angle - 90) * Math.PI / 180, (angle + 90) * Math.PI / 180, true
            );
        return side === 'left' ? [creaseLine, creaseArc] : [creaseArc, creaseLine];
    }

    private buildPaintLines(side: 'top' | 'bottom', blueprintLines: TBlueprintItemEx[]): TBlueprintList[] {
        const edgeCrease: TProfileEdgeCrease = side === 'top' ? 1 : -1;
        const distance = side === 'top' ? this.paintLineDistance : -this.paintLineDistance;

        const paintLines: TBlueprintList[] = [];
        const blueprintLinesLastIndex = blueprintLines.length - 1;
        for (let i = 0; i <= blueprintLinesLastIndex; i++) {
            const item = blueprintLines[i];
            const prevItem = blueprintLines[i - 1];
            const nextItem = blueprintLines[i + 1];

            const prevLine: PlaneLineEx = prevItem instanceof PlaneLineEx ? prevItem : null;
            const nextLine: PlaneLineEx = nextItem instanceof PlaneLineEx ? nextItem : null;

            if (item instanceof PlaneArc) {
                const skipForLeftCrease = i === 1 && this.profile.creaseLeft === edgeCrease;
                const skipForRightCrease = i === blueprintLinesLastIndex - 1 && this.profile.creaseRight === edgeCrease;
                const isExternalArc: boolean = side === 'top'
                    ? prevLine && nextLine
                    && prevLine.start.lengthTo(nextLine.end) < prevLine.topPaintLineStart.lengthTo(nextLine.topPaintLineEnd)
                    : prevLine && nextLine
                    && prevLine.start.lengthTo(nextLine.end) < prevLine.bottomPaintLineStart.lengthTo(nextLine.bottomPaintLineEnd);
                if (skipForLeftCrease || skipForRightCrease || !isExternalArc) {
                    continue;
                }
                const arc: PlaneArc = item;
                paintLines.push(
                    new PlaneArc(arc.center, arc.radius + Math.abs(distance), arc.startAngle, arc.endAngle, arc.anticlockwise)
                );
            } else if (item instanceof PlaneLineEx) {
                const skipForLeftCrease = i === 0 && this.profile.creaseLeft === edgeCrease;
                const skipForRightCrease = i === blueprintLinesLastIndex && this.profile.creaseRight === edgeCrease;
                if (skipForLeftCrease || skipForRightCrease) {
                    continue;
                }
                const line: PlaneLineEx = item;

                const startPoint = this.findPaintLinesIntersection(prevLine, line, side)
                    || MathPlane.getPointOnDistance(line.lineEquation, line.start, distance);

                const endPoint: PlaneRadiusVector = this.findPaintLinesIntersection(line, nextLine, side)
                    || MathPlane.getPointOnDistance(line.lineEquation, line.end, distance);

                paintLines.push(new PlaneLine(startPoint, endPoint, line.isHighlighted));

                if (prevLine) {
                    const isInternalPaintLines: boolean = side === 'top'
                        ? prevLine.start.lengthTo(line.end) > prevLine.topPaintLineStart.lengthTo(line.topPaintLineEnd)
                        : prevLine.start.lengthTo(line.end) > prevLine.bottomPaintLineStart.lengthTo(line.bottomPaintLineEnd);
                    const acuteAngle = MathPlane.acuteAngle(prevLine.start, prevLine.end, line.end);
                    if (!isInternalPaintLines && Math.abs(acuteAngle) <= this.maxAngleToMakeArc) {
                        const startAngle = MathPlane.acuteAngleToHorizontal(prevLine) + Math.PI / 2;
                        const endAngle = MathPlane.acuteAngleToHorizontal(line) + Math.PI / 2;
                        paintLines.push(
                            new PlaneArc(line.start, this.paintLineDistance, startAngle, endAngle, true)
                        );
                    }
                }
            }
        }
        return paintLines;
    }
}
