import { IsString } from 'class-validator';

export class NomenclatureTypeChildParameterGetListRequest {
    @IsString()
    public nomTypeUuidChild: string;

    constructor(data?: Partial<NomenclatureTypeChildParameterGetListRequest>) {
        this.nomTypeUuidChild = data?.nomTypeUuidChild;
    }
}
