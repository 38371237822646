const location = window.location;
let hostApi: string;
if (+location.port === 4200 || +location.port === 4201) {
    hostApi = location.protocol + '//' + location.hostname + ':3000';
} else {
    hostApi = location.origin;
}
const hostWsApi = hostApi.replace(/^http/, 'ws');

export const commonEnvironmentData = {
    production: false,

    hostApi,
    hostWsApiUrl: hostWsApi + '/rest_api_v2/ws',
    hostApiUploads: hostApi + '/uploads/',
};
