import { IsString } from 'class-validator';

export class TaxonomyReferenceRestoreRequest {
    @IsString()
    public uuid: string;

    constructor(data?: Partial<TaxonomyReferenceRestoreRequest>) {
        this.uuid = data?.uuid;
    }
}
