import { RestApiResponse } from '../../common/RestApiResponse';

import { UserInfo } from './UserInfo';

export class UserGetInfoResponse extends RestApiResponse {
    public userInfo: UserInfo;

    constructor(data?: Partial<UserGetInfoResponse>) {
        super(data);
        this.userInfo = data && new UserInfo(data.userInfo);
    }
}
