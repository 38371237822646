import { Observable, of } from 'rxjs';
import { defaultIfEmpty, mergeMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { SessionService } from '../_service/session/session.service';
import { UserService } from '../_service/user.service';

@Injectable()
export class TokenGuard  {
    constructor(private router: Router, private user: UserService, private session: SessionService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.session.userSession$.pipe(
            take(1),
            mergeMap(userSession => {
                if (!userSession.isLoggedIn() && route.firstChild.data.loginByToken) {
                    return this.user.login({ token: route.firstChild.params.token }).pipe(
                        defaultIfEmpty(undefined),
                        mergeMap(user => {
                            if (user) {
                                return of(true);
                            }
                            const target = state.url.split('?')[0];
                            const params = JSON.stringify(route.queryParams);
                            this.router.navigate(['/login'], { queryParams: { t: target, p: params } })
                                .catch(err => console.log(err));
                            return of(false);
                        })
                    );
                }
                return of(true);
            })
        );
    }
}
