import { Injectable } from '@angular/core';
import { LoggerEngine, LoggerTransport } from '@ls-front/sharable';

@Injectable()
export class LoggerService {
    private logger = new LoggerEngine<unknown>();

    public addTransport(transport: LoggerTransport): void {
        this.logger.addTransport(transport);
    }

    public log(message: string, context?: unknown): void {
        this.logger.log(message, context);
    }

    public info(message: string, context?: unknown): void {
        this.logger.info(message, context);
    }

    public warn(message: string, context?: unknown): void {
        this.logger.warn(message, context);
    }

    public error(message: string, context?: unknown): void {
        this.logger.error(message, context);
    }
}
