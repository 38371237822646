import { RestApiResponse } from '../../common/RestApiResponse';
import { NomenclatureTypeChild } from '../NomenclatureTypeChild';

export class NomenclatureTypeChildGetResponse extends RestApiResponse {
    public item: NomenclatureTypeChild;

    constructor(data?: Partial<NomenclatureTypeChildGetResponse>) {
        super(data);
        this.item = new NomenclatureTypeChild(data?.item);
    }
}
