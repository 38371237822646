import { IsString } from 'class-validator';

export class NomenclatureTypeGetRequest {
    @IsString()
    public uuid: string;

    constructor(data?: Partial<NomenclatureTypeGetRequest>) {
        this.uuid = data && data.uuid;
    }
}
