import { buildDate } from '../utils/build-date';

export interface AnnouncementConstruction {
    id?: number;
    title?: string;
    message?: string;
    acceptedUsersCount?: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    acceptedAt?: Date | string;
}

export class Announcement {
    public id: number;
    public title: string;
    public message: string;
    public acceptedUsersCount: number;
    public createdAt: Date;
    public updatedAt: Date;
    public acceptedAt: Date;

    constructor(data?: AnnouncementConstruction) {
        this.id = data?.id;
        this.title = data?.title;
        this.message = data?.message;
        this.acceptedUsersCount = data?.acceptedUsersCount;
        this.createdAt = buildDate(data?.createdAt);
        this.updatedAt = buildDate(data?.updatedAt);
        this.acceptedAt = buildDate(data?.acceptedAt);
    }
}
