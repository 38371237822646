import { IsString } from 'class-validator';

export class UserSetPasswordRequest {
    @IsString()
    public passwordCurrent: string;

    @IsString()
    public passwordNew: string;

    constructor(data?: Partial<UserSetPasswordRequest>) {
        this.passwordCurrent = data && data.passwordCurrent;
        this.passwordNew = data && data.passwordNew;
    }
}
