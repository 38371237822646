import { ArrayNotEmpty, IsArray, IsDefined, IsInt, ValidateIf, isDefined } from 'class-validator';

export class AssignPerformersToOrderPositionsRequest {
    @ValidateIf((o: AssignPerformersToOrderPositionsRequest) => isDefined(o.orderId) || !Array.isArray(o.orderPositionIds) || !o.orderPositionIds.length)
    @IsDefined({ message: '"orderId" is required when "orderPositionIds" is undefined' })
    @IsInt()
    public orderId?: number;

    @ValidateIf((o: AssignPerformersToOrderPositionsRequest) => isDefined(o.areaId) || isDefined(o.orderId))
    @IsDefined({ message: '"areaId" is required when "orderId" is defined' })
    @IsInt()
    public areaId?: number;

    @ValidateIf((o: AssignPerformersToOrderPositionsRequest) => isDefined(o.orderPositionIds) || !isDefined(o.orderId))
    @IsDefined({ message: '"orderPositionIds" is required when "orderId" is undefined' })
    @IsArray()
    @ArrayNotEmpty()
    @IsInt({ each: true })
    public orderPositionIds?: number[];

    @IsArray()
    @ArrayNotEmpty()
    @IsInt({ each: true })
    public performerIds: number[];

    constructor(data?: Partial<AssignPerformersToOrderPositionsRequest>) {
        this.orderId = data?.orderId ?? undefined;
        this.areaId = data?.areaId ?? undefined;
        this.orderPositionIds = data?.orderPositionIds ?? undefined;
        this.performerIds = data?.performerIds ?? undefined;
    }
}
