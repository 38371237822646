import { RestApiResponse } from '../../common/RestApiResponse';
import { NomenclatureTypeChildParameter } from '../NomenclatureTypeChildParameter';

export class NomenclatureTypeChildParameterGetListResponse extends RestApiResponse {
    public parameters?: NomenclatureTypeChildParameter[];

    constructor(data?: Partial<NomenclatureTypeChildParameterGetListResponse>) {
        super(data);
        this.parameters = Array.isArray(data?.parameters) ? data.parameters.map(p => new NomenclatureTypeChildParameter(p)) : [];
    }
}
