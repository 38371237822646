import { IsBoolean, IsString } from 'class-validator';

export class NomenclaturePositionUpdateWidgetRequest {
    @IsString()
    public uuid: string;

    @IsBoolean()
    public isActiveWidget: boolean;

    constructor(data?: Partial<NomenclaturePositionUpdateWidgetRequest>) {
        this.uuid = data?.uuid;
        this.isActiveWidget = data?.isActiveWidget;
    }
}
