import { sha1 } from 'object-hash';
import { EMPTY, Observable, UnaryFunction, of, pipe, range, throwError, timer, zip } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { RestApiResponse, RestApiResponseMessage } from '@ls-front/sharable';
import { environment } from '@ls-front/env';

import { LsStorage } from '../common/ls-storage';

import { MessengerService } from './messenger.service';
import { CustomCacheService } from './custom-cache.service';
import { LoggerService } from './logger/logger.service';
import { detectFramedRun } from './detect-framed-run';

interface IHttpOptions {
    withCredentials?: boolean;
    observe: 'response';
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
}

@Injectable({ providedIn: 'root' })
export class ApiService {
    // Версия приложения
    public readonly authTokenHeader = 'Authorization';

    private httpOptions: IHttpOptions = { observe: 'response' };
    private storeDebugParams = '';

    private readonly maxReconnectAttempts = 3;
    private readonly baseReconnectDelay = 1500;

    public errorAuthFilter: (response: HttpErrorResponse) => boolean = () => true;

    constructor(private httpClient: HttpClient, private msg: MessengerService, route: ActivatedRoute,
                private cache: CustomCacheService, private logger: LoggerService) {
        const framedRun = detectFramedRun();
        this.httpOptions.headers = {
            // Send headers for gathering statistics about running Lisa10 in <iframe .../> on other domains
            /* eslint-disable @typescript-eslint/naming-convention */
            'X-Lisa10-Referrer': framedRun.parentDomain,
            'X-Lisa10-Framed': String(framedRun.isFramed),
            /* eslint-enable @typescript-eslint/naming-convention */
        };
        route.queryParams.subscribe(params => {
            if (params.debug) {
                this.httpOptions.withCredentials = true;
                this.storeDebugParams = '?debug=1';
            }
            if (params.hasOwnProperty('nocache')) {
                this.disableCacheRequests = true;
            }
        });
    }

    /**
     * On first call will send request and caches data of response by tag. On subsequent calls return cache data.
     * Cache auto removed after 2 minutes.
     */
    public getCached<T>(relativeUrl: string, cacheTag: string, postData?: object): Observable<T> {
        const cacheKey = sha1({ relativeUrl, postData });

        if (!this.disableCacheRequests && this.cache.exists(cacheKey)) {
            return of(this.cache.get(cacheKey));
        }

        return this.post<T>(relativeUrl, postData)
            .pipe(tap(data => {
                // Caching
                if (!this.disableCacheRequests && cacheTag) {
                    this.cache.set(cacheKey, data, { tag: cacheTag, maxAge: 2 * 60 });
                }
            })) as Observable<T>;
    }

    /** Send request and after completed, remove all caches by tag */
    public updateCached<T>(relativeUrl: string, cacheTag: string | string[], postData?: object): Observable<T> {
        return this.post<T>(relativeUrl, postData).pipe(
            tap(() => this.clearCache(cacheTag)), // clear cache after request
            catchError(error => {             // clear cache on fail request
                this.clearCache(cacheTag);
                return throwError(() => error);
            })
        );
    }

    /** Send POST request to api backend */
    public post<T>(relativeUrl: string, postData?: object): Observable<T> {
        return this.httpClient
            .post<T>(environment.hostApi + relativeUrl + this.debugParams, postData, { ...this.httpOptions, responseType: 'json' })
            .pipe(
                this.rxHandleHttpErrors(),
                // Map data
                map((response: HttpResponse<T>) => response.body),
                // Вывод сообщений полученных от сервера
                tap(data => {
                    const response = new RestApiResponse(data);
                    if (response.messages) {
                        this.showMessages(response.messages);
                    }
                }),
                // Check errors
                mergeMap(data => {
                    const response = new RestApiResponse(data);
                    if (typeof response.success !== 'undefined' && !response.success) {
                        return EMPTY;
                    }
                    return of(data as T);
                })
            );
    }

    public downloadFile(relativeUrl: string, postData?: object): Observable<void> {
        return this.httpClient
            .post(environment.hostApi + relativeUrl + this.debugParams, postData, { ...this.httpOptions, responseType: 'blob' })
            .pipe(
                this.rxHandleHttpErrors(),
                tap((data: HttpResponse<Blob>) => {
                    let fileName = data.headers.get('Content-Disposition');
                    fileName = fileName ? fileName.split(';')[1].split('=')[1].replace(/"/g, '') : fileName;
                    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
                    if (blob.size > 0) {
                        saveAs(blob, fileName);
                    }
                }),
                map(() => undefined),
                catchError(error => {
                    this.logger.log('Error downloading the file.', error);
                    return EMPTY;
                }),
                finalize(() => console.log('downloadFile: OK'))
            );
    }

    private rxHandleHttpErrors<T>(): UnaryFunction<Observable<T>, Observable<T>> {
        return pipe<Observable<T>, Observable<T>>(
            // Reconnect after errors
            retryWhen(attempts => zip(
                attempts.pipe(
                    filter((response: HttpErrorResponse) => this.errorAuthFilter(response)),
                    mergeMap((response: HttpErrorResponse) => {
                        const error = new RestApiResponse(response.error);
                        if (!response.ok && response.status > 0) {
                            if (Array.isArray(error.messages)) {
                                this.showMessages(error.messages);
                            } else if (response.message) {
                                this.msg.customMessage(
                                    response.status,
                                    response.error?.name || response.name,
                                    response.error?.message || response.message
                                );
                            }
                            return throwError(() => response.message);
                        }
                        return of(response);
                    })
                ),
                range(1, this.maxReconnectAttempts + 1)
            ).pipe(
                mergeMap(([response, attemptNo]: [HttpErrorResponse, number]) => {
                    if (attemptNo > this.maxReconnectAttempts) {
                        this.logger.warn(`Retry connection failed after ${this.maxReconnectAttempts} attempts`, undefined);
                        return throwError(() => response);
                    }
                    this.logger.log(`${attemptNo} failed connection attempt`, undefined);
                    return timer(attemptNo * this.baseReconnectDelay);
                })
            ))
        );
    }

    public clearCacheAll(): void {
        this.cache.removeAll();
    }

    public clearCache(cacheTag: string | string[]): void {
        [].concat(cacheTag).forEach((tag: string) => this.cache.removeTag(tag));
    }

    get debugParams(): string {
        return this.storeDebugParams;
    }

    private showMessages(messages: RestApiResponseMessage[]): void {
        if (Array.isArray(messages)) {
            messages.forEach(message => {
                this.msg.customMessage(message.code, '', message.text);
            });
        }
    }

    // noinspection JSMethodCanBeStatic
    private get disableCacheRequests(): boolean {
        return LsStorage.get<boolean>('lisa.noCacheRequests');
    }

    // noinspection JSMethodCanBeStatic
    private set disableCacheRequests(value: boolean) {
        LsStorage.set<boolean>('lisa.noCacheRequests', value);
    }
}
