import { ITPDate } from '../third-party/ITPDate';
import { TPDateModel } from '../third-party/TPDateModel';

import { TOrderStatus } from './order-status';

export interface OrderSearchRaw {
    orderId: number;
    contrId: number;
    userId: number;
    totalSum: number;
    status?: TOrderStatus;
    sumRange: number;
    date: ITPDate;
    dateRange: number;
    pickupAddress: string;
    comment: string;
}

export class COrderSearch {
    public orderId: number;
    public contrId: number;
    public userId: number;
    public totalSum: number;
    public status: TOrderStatus;
    public sumRange: number;
    public date: TPDateModel;
    public dateRange: number;
    public pickupAddress: string;
    public comment: string;

    constructor(data?: Partial<OrderSearchRaw>) {
        this.orderId = data?.orderId || null;
        this.contrId = data?.contrId || null;
        this.userId = data?.userId || null;
        this.totalSum = data?.totalSum || null;
        this.status = data?.status || null;
        this.sumRange = data?.sumRange || null;
        this.dateRange = data?.dateRange || null;
        this.pickupAddress = data?.pickupAddress || null;
        this.comment = data?.comment || null;

        // todo: here is a mapping dates due to moving from package `mydatepicker` to `@ng-bootstrap`
        this.date = data?.date?.year && data?.date?.month && data?.date?.day
            ? new TPDateModel({
                date: data.date,
                jsdate: new Date(data.date.year, data.date.month - 1, data.date.day).toISOString(),
                formatted: '',
                epoc: 0,
            })
            : null;
    }

    public getRaw(): OrderSearchRaw {
        return {
            orderId: this.orderId,
            contrId: this.contrId,
            userId: this.userId,
            totalSum: this.totalSum,
            sumRange: this.sumRange,
            date: this.date?.date || null,
            dateRange: this.dateRange,
            pickupAddress: this.pickupAddress,
            comment: this.comment,
        };
    }
}
