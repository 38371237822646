import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidator {
    public readonly validatorFn: ValidatorFn;

    private internalValid = true;

    constructor(public id: string, public message: string, externalValidatorFn: ValidatorFn | ValidatorFn[]) {
        externalValidatorFn = Array.isArray(externalValidatorFn) ? externalValidatorFn : [externalValidatorFn];
        this.validatorFn = (control: AbstractControl) => {
            const errors = externalValidatorFn
                .map(fn => fn(control))
                .filter(e => !!e)
                .reduce((a, e) => ({ ...a, ...e }), null);
            this.internalValid = !errors;
            return errors;
        };
    }

    public get valid(): boolean {
        return this.internalValid;
    }
}
