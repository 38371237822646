import { IsInt } from 'class-validator';

export class DealerDeleteRequest {
    @IsInt()
    public readonly id: number;

    constructor(data?: Partial<DealerDeleteRequest>) {
        this.id = data && data.id;
    }
}
