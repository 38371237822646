import { RestApiResponse } from '../common/RestApiResponse';

import { CrudPaginateResponse } from './CrudPaginateResponse';

export class CrudList<T> extends RestApiResponse {
    public items: T[];
    public pagination?: CrudPaginateResponse;

    constructor(data?: Partial<CrudList<T>>, itemClass?: new (itemData?: Partial<T>) => T) {
        super(data);
        this.pagination = data && data.pagination ? new CrudPaginateResponse(data.pagination) : undefined;

        if (data && Array.isArray(data.items)) {
            this.items = itemClass ? data.items.map(i => new itemClass(i)) : data.items;
        } else {
            this.items = [];
        }
    }
}
