import { AreaSimple } from './area-simple';

export interface AreaPerson {
    id: number;
    userId?: number;
    text: string;
}

export interface AreaNomType {
    uuid: string;
    name: string;
}

export class Area extends AreaSimple {
    public persons: Array<AreaPerson>;
    public nomTypes: Array<AreaNomType>;

    constructor(data?: Partial<Area>) {
        super(data);
        this.persons = data?.persons;
        this.nomTypes = data?.nomTypes;
    }
}
