import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UserLoginRequest, UserLoginResponse } from '@ls-front/sharable';

import { ApiService } from '../api.service';
import { SessionService } from '../session/session.service';
import { UserSession } from '../session/user-session';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(private api: ApiService, private session: SessionService) {
    }

    public login(request: UserLoginRequest): Observable<UserSession> {
        return this.api.post<UserLoginResponse>('/rest_api_v2/account/login', request).pipe(
            map(data => {
                const response = new UserLoginResponse(data);
                const userSession = new UserSession(response.userLoginInfo);
                this.session.setUserSession(userSession);
                return userSession;
            })
        );
    }

    public logout(withoutRequest = false): Observable<void> {
        const logout$ = withoutRequest
            ? of(undefined)
            : this.api.post('/rest_api_v2/account/logout').pipe(tap(() => this.api.clearCacheAll()));

        return logout$.pipe(
            map(() => {
                this.session.clearUserSession();
                return undefined;
            })
        );
    }
}
