import { IsInt } from 'class-validator';

export class AreaAddPersonRequest {
    @IsInt()
    public personId: number;

    @IsInt()
    public areaId: number;

    constructor(data?: Partial<AreaAddPersonRequest>) {
        this.personId = data?.personId;
        this.areaId = data?.areaId;
    }
}
