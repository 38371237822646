import { PlaneRadiusVector } from './PlaneRadiusVector';

export class PlaneArc {
    constructor(
        public center: PlaneRadiusVector,
        public radius: number,
        public startAngle: number,
        public endAngle: number,
        public anticlockwise: boolean
    ) {
    }
}
