import { ArrayNotEmpty, IsArray, ValidateNested } from 'class-validator';

import { Setting } from './Setting';

export class SettingsUpdateRequest {
    @IsArray()
    @ArrayNotEmpty()
    @ValidateNested()
    public settings: Setting[];

    constructor(data?: Partial<SettingsUpdateRequest>) {
        this.settings = Array.isArray(data?.settings) && data?.settings.map(setting => new Setting(setting));
    }
}
