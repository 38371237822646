import { truncate } from 'lodash';
import { IsBoolean, IsEmail, IsInt, IsOptional, IsString, MaxLength, ValidateIf } from 'class-validator';

export class ContragentUpdateRequest {
    @IsOptional()
    @IsInt()
    public readonly id?: number;

    @IsOptional()
    @IsString()
    public readonly priceTypeUuid?: string;

    @IsString()
    @MaxLength(255)
    public readonly name?: string;

    @IsString()
    @MaxLength(25)
    public readonly shortName?: string;

    @IsOptional()
    @IsString()
    @MaxLength(255)
    public readonly id1C?: string;

    @IsOptional()
    @IsString()
    public readonly phone?: string;

    @ValidateIf((o: ContragentUpdateRequest) => !!o.email) // allow empty string
    @IsString()
    @IsEmail()
    public readonly email?: string;

    @IsOptional()
    @IsInt()
    public readonly dealerId?: number;

    @IsOptional()
    @IsBoolean()
    public readonly isForGauger?: boolean;

    @IsOptional()
    @IsInt()
    public readonly ordersLimit?: number;

    @IsOptional()
    @IsBoolean()
    public readonly shippingIncluded?: boolean;

    @IsOptional()
    @IsString()
    public readonly contactPhone?: string;

    @IsOptional()
    @IsString()
    public readonly contactPerson?: string;

    constructor(data?: Partial<ContragentUpdateRequest>) {
        this.id = data && data.id;
        this.priceTypeUuid = data && data.priceTypeUuid;
        this.name = data && data.name;
        this.shortName = data && data.shortName || truncate(this.name, { length: 25 });
        this.id1C = data && data.id1C;
        this.phone = data && data.phone;
        this.email = data && data.email;
        this.dealerId = data && data.dealerId;
        this.isForGauger = data && data.isForGauger;
        this.ordersLimit = data && data.ordersLimit || 0;
        this.shippingIncluded = data && data.shippingIncluded || false;
        this.contactPhone = data && data.contactPhone;
        this.contactPerson = data && data.contactPerson;
    }
}
