import { TaxonomyItem, TaxonomyValues } from '..';

import { NomenclaturePositionChild } from './NomenclaturePositionChild';

export class NomenclaturePosition implements TaxonomyItem {
    public uuid: string;
    public nomTypeUuid?: string;
    public nomTypeName?: string;
    public withParams?: boolean;
    public isActiveWidget?: boolean;
    public values?: TaxonomyValues & {
        f_inv?: string;
        f_name?: string;
        f_price?: number;
        f_image?: string;
        f_measure?: string;
        f_category?: string[];
        f_print_photo?: number;
    };
    public childPositions?: NomenclaturePositionChild[];

    // For internal usage
    public qty?: number;

    constructor(data?: Partial<NomenclaturePosition>) {
        this.uuid = data?.uuid;
        this.nomTypeUuid = data?.nomTypeUuid;
        this.nomTypeName = data?.nomTypeName;
        this.withParams = data?.withParams;
        this.isActiveWidget = data?.isActiveWidget;
        this.values = data?.values || {};
        this.childPositions = data?.childPositions || [];
        this.qty = data?.qty;
    }
}
