import { LoggerTransport } from '../interfaces/LoggerTransport';
import { LogLevel } from '../LogLevel';

/* eslint-disable no-console */
export class ConsoleLoggerTransport implements LoggerTransport {
    public log(level: LogLevel, message: string, context: unknown): void {
        switch (level) {
            case LogLevel.log:
                console.log(message, context);
                break;
            case LogLevel.info:
                console.info(message, context);
                break;
            case LogLevel.warn:
                console.warn(message, context);
                break;
            case LogLevel.error:
                console.error(message, context);
                break;
        }
    }
}
