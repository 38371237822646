import { IsInt, IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../../crud/CrudItemListRequest';

export class PersonListRequest extends CrudItemListRequest {
    @IsOptional()
    @IsInt()
    public personId?: number;

    @IsOptional()
    @IsInt()
    public dealerId?: number;

    @IsOptional()
    @IsString()
    public searchLastName?: string;

    constructor(data?: Partial<PersonListRequest>) {
        super(data);
        this.personId = data?.personId;
        this.dealerId = data?.dealerId;
        this.searchLastName = data?.searchLastName;
    }
}
