import { IsEnum, IsOptional, IsString } from 'class-validator';

import { EGroup } from '../EGroup';

export class PermissionListRequest {
    @IsOptional()
    @IsString()
    @IsEnum(EGroup)
    public group?: EGroup;

    constructor(data?: Partial<PermissionListRequest>) {
        this.group = data && data.group;
    }
}
