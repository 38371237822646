import { IsInt, IsOptional, IsString, MaxLength } from 'class-validator';

export class DealerUpdateRequest {
    @IsOptional()
    @IsInt()
    public readonly id?: number;

    @IsString()
    @MaxLength(255)
    public readonly name?: string;

    @IsOptional()
    @IsInt()
    public readonly contrGaugerId?: number;

    constructor(data?: Partial<DealerUpdateRequest>) {
        this.id = data && data.id;
        this.name = data && data.name;
        this.contrGaugerId = data && data.contrGaugerId;
    }
}
