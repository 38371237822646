export class UserLoginInfo {
    public token: string;
    public group: string;
    public groupTitle: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public roles: string[];
    public permissions: string[];
    public areas: number[];
    public username: string;
    public userId: number;

    constructor(data?: Partial<UserLoginInfo>) {
        this.token = data?.token;
        this.group = data?.group;
        this.groupTitle = data?.groupTitle;
        this.firstName = data?.firstName;
        this.middleName = data?.middleName;
        this.lastName = data?.lastName;
        this.roles = data?.roles;
        this.permissions = data?.permissions;
        this.areas = data?.areas;
        this.username = data?.username;
        this.userId = data?.userId;
    }
}
