import { IsBoolean, IsOptional } from 'class-validator';

import { CrudItemListRequest } from '../crud';

export class AreaGetListRequest extends CrudItemListRequest {
    @IsOptional()
    @IsBoolean()
    public forMenu?: boolean;

    constructor(data?: Partial<AreaGetListRequest>) {
        super(data);
        this.forMenu = data?.forMenu;
    }
}
