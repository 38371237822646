import { AssemblyLocation } from '../settings';

import { OrderPosition } from './positions/order-position';
import { OrderStatus } from './order-status';
import { IOrderExternalData } from './external-data/IOrderExternalData';

export class Order {
    public id: number;
    public status: OrderStatus;
    public contrId: number;
    public orderNeedDelivery: boolean;
    public contrNeedDelivery: boolean;
    public comment: string;
    public shortNote: string;
    public sum: number;
    public totalSum: number;
    public discount: number;
    public personId: number;
    public created: number;
    public updated: number;
    public contrName: string;
    public userName: string;
    public positions: OrderPosition[];
    public dealerId: number;
    public gaugerId: number;
    public priceTypeUuid: string;
    public paidOnline: boolean;
    public externalData: IOrderExternalData;
    public serviceComment: string;
    public assemblyLocation: AssemblyLocation;
    public pickupAddress: string;
    public pickupDate: number;
    public contactPhone: string;
    public contactPerson: string;

    constructor(data?: Partial<Order>) {
        this.id = data?.id || 0;
        this.status = data?.status || OrderStatus.STATUS_NEW;
        this.contrId = data?.contrId || 0;
        this.orderNeedDelivery = data?.orderNeedDelivery || false;
        this.contrNeedDelivery = data?.contrNeedDelivery || false;
        this.comment = data?.comment || '';
        this.shortNote = data?.shortNote || '';
        this.sum = data?.sum || 0;
        this.discount = data?.discount || 0;
        this.totalSum = data?.totalSum || 0;
        this.personId = data?.personId || 0;
        this.created = data?.created || 0;
        this.updated = data?.updated || 0;
        this.contrName = data?.contrName || '';
        this.userName = data?.userName || '';
        this.positions = Array.isArray(data?.positions) ? data?.positions.map(p => new OrderPosition(p)) : [];
        this.dealerId = data?.dealerId || null;
        this.gaugerId = data?.gaugerId || null;
        this.priceTypeUuid = data?.priceTypeUuid || null;
        this.paidOnline = data?.paidOnline || false;
        this.externalData = data?.externalData;
        this.assemblyLocation = data?.assemblyLocation;
        this.pickupAddress = data?.pickupAddress;
        this.pickupDate = data?.pickupDate || null;
        this.serviceComment = data?.serviceComment;
        this.contactPhone = data?.contactPhone;
        this.contactPerson = data?.contactPerson;
    }
}
