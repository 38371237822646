import { IsInt, IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../crud/CrudItemListRequest';
import { CrudShowMode } from '../crud';

export class ContragentListRequest extends CrudItemListRequest {
    @IsOptional()
    @IsString()
    public search?: string;

    @IsOptional()
    @IsInt()
    public contragentId?: number;

    @IsOptional()
    @IsInt()
    public dealerId?: number;

    @IsOptional()
    @IsString()
    public priceTypeUuid?: string;

    constructor(data?: Partial<ContragentListRequest>) {
        super(data);
        this.search = data && data.search;
        this.contragentId = data && data.contragentId;
        this.dealerId = data && data.dealerId;
        this.priceTypeUuid = data && data.priceTypeUuid;
        if (!this.show) {
            this.show = CrudShowMode.all;
        }
    }
}
