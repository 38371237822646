import { AreaType } from './AreaType';

export class AreaSimple {
    public id: number;
    public name: string;
    public tag: string;
    public typeName?: string;
    public typeId?: AreaType;
    public isSystem?: boolean;

    constructor(data?: Partial<AreaSimple>) {
        this.id = data?.id;
        this.name = data?.name;
        this.tag = data?.tag;
        this.typeName = data?.typeName;
        this.typeId = data?.typeId;
        this.isSystem = data?.isSystem;
    }
}
