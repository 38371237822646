import { IsString } from 'class-validator';

export class NomenclaturePositionCompositionGetRequest {
    @IsString()
    public nomTypeUuid: string;

    constructor(data?: Partial<NomenclaturePositionCompositionGetRequest>) {
        this.nomTypeUuid = data && data.nomTypeUuid;
    }
}
