import { IsInt } from 'class-validator';

import { Announcement } from '../announcement';

export class AnnouncementAcceptRequest implements Pick<Announcement, 'id'> {
    @IsInt()
    public readonly id: number;

    constructor(data?: Partial<AnnouncementAcceptRequest>) {
        this.id = data?.id;
    }
}

