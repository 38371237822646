import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { EGroup } from '@ls-front/sharable';

import { LsStorage } from '../../common/ls-storage';

import { UserSession } from './user-session';

@Injectable({ providedIn: 'root' })
export class SessionService {
    public readonly userSession$: Observable<UserSession>;

    private readonly userSessionKey = 'lisa.core.rbac.auth';
    private readonly internalUserSession$ = new BehaviorSubject<UserSession>(new UserSession());

    constructor() {
        this.userSession$ = this.internalUserSession$.asObservable();
        const userSessionData = LsStorage.get<UserSession>(this.userSessionKey);
        this.internalUserSession$.next(new UserSession(userSessionData));
    }

    public setUserSession(userSession: UserSession): void {
        LsStorage.set<UserSession>(this.userSessionKey, userSession);
        this.internalUserSession$.next(userSession);
    }

    public clearUserSession(): void {
        LsStorage.remove(this.userSessionKey);
        this.internalUserSession$.next(new UserSession());
    }

    public userCan(permissionName: string | string[]): Observable<boolean> {
        return this.userSession$.pipe(map(userSession => userSession.can(permissionName)));
    }

    public userGroup(group: EGroup): Observable<boolean> {
        return this.userSession$.pipe(map(userSession => userSession.group === group));
    }

    public userRole(role: string): Observable<boolean> {
        return this.userSession$.pipe(map(userSession => userSession.roles.includes(role)));
    }
}
