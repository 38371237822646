import { IsNotEmpty, IsString } from 'class-validator';

export class TaxonomyReferenceFieldRestoreRequest {
    @IsString()
    @IsNotEmpty()
    public uuid: string;

    constructor(data?: Partial<TaxonomyReferenceFieldRestoreRequest>) {
        this.uuid = data?.uuid;
    }
}
