import { IsInt } from 'class-validator';

export class PersonDeleteRequest {
    @IsInt()
    public id: number;

    constructor(data?: Partial<PersonDeleteRequest>) {
        this.id = data?.id;
    }
}
