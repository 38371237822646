import { IsArray, IsIn, IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../../crud/CrudItemListRequest';
import { NomenclatureAccountingType } from '../enum/NomenclatureAccountingType';
import { SpecificationType } from '../../specification';

export class NomenclatureTypeGetListRequest extends CrudItemListRequest {
    @IsOptional()
    @IsIn([SpecificationType.MOSQUITO_NETTING])
    public filterBySpecificationType?: SpecificationType;

    /**
     * Skip nomenclature type by supplied uuid and all parents
     */
    @IsOptional()
    @IsString()
    public filterByNomenclatureTypeUuid?: string;

    @IsOptional()
    @IsArray()
    @IsIn([
        NomenclatureAccountingType.ACCOUNTING_TYPE_KIT,
        NomenclatureAccountingType.ACCOUNTING_TYPE_PRODUCT,
        NomenclatureAccountingType.ACCOUNTING_TYPE_SERVICE,
    ], { each: true })
    public accountingType?: NomenclatureAccountingType[];

    constructor(data?: Partial<NomenclatureTypeGetListRequest>) {
        super(data);
        this.filterBySpecificationType = data && data.filterBySpecificationType;
        this.filterByNomenclatureTypeUuid = data && data.filterByNomenclatureTypeUuid;
        this.accountingType = data && data.accountingType;
    }
}
