import { RestApiResponse } from '../common/RestApiResponse';

import { Area } from './area';

export class AreaUpdateResponse extends RestApiResponse {
    public item: Area;

    constructor(data?: Partial<AreaUpdateResponse>) {
        super(data);
        this.item = data && data.item;
    }
}
