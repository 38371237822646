import { ILineEquation, IPlaneLinePoints, IPlanePoint } from './interfaces';
import { PlaneRadiusVector } from './PlaneRadiusVector';
import { PlaneLine } from './PlaneLine';

/**
 * Implements math on a plane
 */
export class MathPlane {
    /**
     * Detect intersection of lines line1 and line2
     */
    public static intersects(line1: IPlaneLinePoints, line2: IPlaneLinePoints): boolean {
        return MathPlane.intersectsByPoints(line1.start, line1.end, line2.start, line2.end);
    }

    /**
     * Detect intersection of lines line1 and line2 defined by points: start1, end1, start2, end2
     * Returns true if the lines cross each other.
     * Returns false if the lines do not cross each other or cross in the ends.
     */
    public static intersectsByPoints(start1: IPlanePoint, end1: IPlanePoint, start2: IPlanePoint, end2: IPlanePoint): boolean {
        const v1 = (end2.x - start2.x) * (start1.y - start2.y) - (end2.y - start2.y) * (start1.x - start2.x);
        const v2 = (end2.x - start2.x) * (end1.y - start2.y) - (end2.y - start2.y) * (end1.x - start2.x);
        const v3 = (end1.x - start1.x) * (start2.y - start1.y) - (end1.y - start1.y) * (start2.x - start1.x);
        const v4 = (end1.x - start1.x) * (end2.y - start1.y) - (end1.y - start1.y) * (end2.x - start1.x);
        return (v1 * v2 < 0) && (v3 * v4 < 0);
    }

    /**
     * Returns a point of crossing lines via line equations
     */
    public static intersectPoint(abc1: ILineEquation, abc2: ILineEquation): PlaneRadiusVector {
        const delta = abc1.a * abc2.b - abc1.b * abc2.a;
        return delta === 0 ? null
            : new PlaneRadiusVector(-(abc1.c * abc2.b - abc1.b * abc2.c) / delta, (abc1.c * abc2.a - abc1.a * abc2.c) / delta);
    }

    /**
     * Returns coefficients of line equation `a*x + b*y + c = 0`
     * @see https://www.desmos.com/calculator
     */
    public static lineEquation(line: IPlaneLinePoints): ILineEquation {
        return MathPlane.lineEquationByPoints(line.start, line.end);
    }

    /**
     * Returns coefficients of line equation `a*x + b*y + c = 0`
     * @see https://www.desmos.com/calculator
     */
    public static lineEquationByPoints(point1: IPlanePoint, point2: IPlanePoint): ILineEquation {
        return {
            a: point2.y - point1.y,
            b: point1.x - point2.x,
            c: point2.x * point1.y - point1.x * point2.y,
        };
    }

    public static orthogonalLineEquation(baseLineEquation: ILineEquation, point: IPlanePoint): ILineEquation {
        return {
            a: baseLineEquation.b,
            b: -baseLineEquation.a,
            c: -(baseLineEquation.b * point.x - baseLineEquation.a * point.y),
        };
    }

    public static rotateLineEquation(abc: ILineEquation, point: IPlanePoint, angle: number): ILineEquation {
        angle = -1 * angle;
        const { x, y } = MathPlane.getPointOnLine(abc, point, -10);
        const { x: cx, y: cy } = point;
        const x2 = ((x - cx) * Math.cos(angle) + (y - cy) * Math.sin(angle)) + cx;
        const y2 = (-(x - cx) * Math.sin(angle) + (y - cy) * Math.cos(angle)) + cy;
        return MathPlane.lineEquationByPoints({ x: point.x, y: point.y }, { x: x2, y: y2 });
    }

    public static parallelLineEquation(baseLineEquation: ILineEquation, distance: number): ILineEquation {
        const v2 = Math.sqrt(baseLineEquation.a * baseLineEquation.a + baseLineEquation.b * baseLineEquation.b);
        return {
            a: baseLineEquation.a,
            b: baseLineEquation.b,
            c: baseLineEquation.c - distance * v2,
        };
    }

    public static getParallelLine(baseLine: IPlaneLinePoints, distance: number): PlaneLine {
        const baseLineEquation = MathPlane.lineEquation(baseLine);
        const parallelLineEquation = MathPlane.parallelLineEquation(baseLineEquation, distance);
        const startOrthogonalEquation = MathPlane.orthogonalLineEquation(baseLineEquation, baseLine.start);
        const endOrthogonalEquation = MathPlane.orthogonalLineEquation(baseLineEquation, baseLine.end);
        return new PlaneLine(
            MathPlane.intersectPoint(parallelLineEquation, startOrthogonalEquation),
            MathPlane.intersectPoint(parallelLineEquation, endOrthogonalEquation),
            false
        );
    }

    public static getDistanceBetweenPointAndLine(point: IPlanePoint, abc: ILineEquation): number {
        return (abc.a * point.x + abc.b * point.y + abc.c) / Math.sqrt(abc.a * abc.a + abc.b * abc.b);
    }

    /**
     * Get a point on a distance from a line defined by the line equation and a basic point
     */
    public static getPointOnDistance(lineEquation: ILineEquation, basePoint: IPlanePoint, distance: number): PlaneRadiusVector {
        return MathPlane.intersectPoint(
            MathPlane.orthogonalLineEquation(lineEquation, basePoint),
            MathPlane.parallelLineEquation(lineEquation, distance)
        );
    }

    public static getPointOnLine(abc: ILineEquation, basePoint: IPlanePoint, distance: number): PlaneRadiusVector {
        const dX = distance * abc.b / Math.sqrt(abc.a * abc.a + abc.b * abc.b);
        const dY = distance * abc.a / Math.sqrt(abc.a * abc.a + abc.b * abc.b);
        return abc.a === 0 || abc.b === 0
            ? new PlaneRadiusVector(basePoint.x - dX, basePoint.y - dY)
            : new PlaneRadiusVector(basePoint.x - dX, basePoint.y + dY);
    }

    /**
     * Returns acute angle in radians between axis X and a line defined by a line equation
     */
    public static acuteAngleByLineEquation(abc: ILineEquation): number {
        const angle = Math.atan2(-abc.a, abc.b);
        return angle >= 0 ? angle : Math.PI + angle;
    }

    /**
     * Returns acute angle between two line defined by line equations
     */
    public static acuteAngleBetweenLines(abc1: ILineEquation, abc2: ILineEquation): number {
        return MathPlane.acuteAngleByLineEquation(abc1) - MathPlane.acuteAngleByLineEquation(abc2);
    }

    /**
     * Returns the value of the acute angle defined by three points (p2 is the angular point) in radians
     * возвращает величину острого угла, определяемый тремя точками (p2 - угловая точка)
     */
    public static acuteAngle(p1: PlaneRadiusVector, p2: PlaneRadiusVector, p3: PlaneRadiusVector): number {
        const divider = 2 * p1.lengthTo(p2) * p2.lengthTo(p3);
        if (divider === 0) {
            const pStr: (p: IPlanePoint) => string = p => `x=${p.x}; y=${p.y}`;
            // eslint-disable-next-line no-console
            console.warn(`Can not calculate the acute angle for points: A(${pStr(p1)}), B(${pStr(p2)}), C(${pStr(p3)})`);
            return NaN;
        }
        const x = -(p1.lengthSqrTo(p3) - p1.lengthSqrTo(p2) - p2.lengthSqrTo(p3)) / divider;
        return Math.acos(Math.max(-1, Math.min(1, x)));
    }

    /**
     * Retrieve an acute angle between the line and the horizontal
     */
    public static acuteAngleToHorizontal(line: { start: PlaneRadiusVector; end: PlaneRadiusVector }): number {
        const controlPoint = new PlaneRadiusVector(Math.max(line.start.x, line.end.x) + 100, line.start.y);
        return MathPlane.acuteAngle(line.end, line.start, controlPoint) * (line.start.y > line.end.y ? -1 : 1);
    }

    public static toRadians(angleDegree: number): number {
        return angleDegree * Math.PI / 180;
    }

    public static toDegrees(angleRadians: number): number {
        return angleRadians * 180 / Math.PI;
    }

    public static lineEquationToString(abc: ILineEquation): string {
        return `${abc.a}x+${abc.b}y+${abc.c}=0`.replace(/\+-/g, '-');
    }

    public static pointToString(point: IPlanePoint): string {
        return `x=${point.x}; y=${point.y}`;
    }

    /**
     * ray-casting algorithm based on
     * @see https://github.com/substack/point-in-polygon/blob/master/index.js
     */
    public static isPointInsidePolygon(point: IPlanePoint, polygon: IPlanePoint[]): boolean {
        const x = point.x, y = point.y;
        let inside = false;
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].x, yi = polygon[i].y;
            const xj = polygon[j].x, yj = polygon[j].y;
            const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    }

    public static range(start: number, stop: number, step: number = 1): number[] {
        const direction = start < stop ? 1 : -1;
        step = direction * Math.abs(step);
        const range = [];
        while (direction > 0 ? start <= stop : start >= stop) {
            range.push(start);
            start += step;
        }
        return range;
    }

    public static rangeWithOpposites(start: number, stop: number, step: number = 1): number[] {
        const range: number[] = [];
        MathPlane.range(start, stop, step).forEach(v => (v ? range.push(v, -1 * v) : range.push(v)));
        return range;
    }
}
