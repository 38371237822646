export class UserInfo {
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public phone: string;
    public email: string;

    constructor(data?: Partial<UserInfo>) {
        this.firstName = data && data.firstName || '';
        this.middleName = data && data.middleName || '';
        this.lastName = data && data.lastName || '';
        this.phone = data && data.phone || '';
        this.email = data && data.email || '';
    }
}
