import { RestApiResponse } from '../../common/RestApiResponse';

export class UserGetPermanentAuthTokenResponse extends RestApiResponse {
    public token: string;

    constructor(data?: Partial<UserGetPermanentAuthTokenResponse>) {
        super(data);
        this.token = data && data.token;
    }
}
