export class OrganisationInfo {
    /** Used as a franchiser identifier */
    public readonly franchiserName: string;
    public readonly organisationLogo?: string;
    public readonly organisationBankPaymentQr?: string;
    public readonly tinkoffKassaMerchantToken?: string;
    public readonly yooKassaEnabled?: boolean;
    public readonly noVAT?: boolean;

    constructor(data?: Partial<OrganisationInfo>) {
        this.franchiserName = data?.franchiserName || '';
        this.organisationLogo = data?.organisationLogo || '';
        this.organisationBankPaymentQr = data?.organisationBankPaymentQr || '';
        this.tinkoffKassaMerchantToken = data?.tinkoffKassaMerchantToken || '';
        this.yooKassaEnabled = data?.yooKassaEnabled || false;
        this.noVAT = data?.noVAT || false;
    }
}
