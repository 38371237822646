import { ValidatorFn } from '@angular/forms';

import { CustomValidator } from './custom-validator';

export class CustomValidatorList {
    constructor(public readonly validatorList: CustomValidator[]) {
    }

    public get validators(): ValidatorFn[] {
        return this.validatorList.map(v => v.validatorFn);
    }

    public get firstError(): string {
        return this.validatorList.find(v => !v.valid)?.message || '';
    }
}
