import { IsString, Matches } from 'class-validator';

export class TaxonomyBaseFieldRestoreRequest {
    @IsString()
    @Matches(
        /^f_[a-zA-Z_0-9]+$/,
        { message: 'name должно содержать только буквы латинского алфавита, цифры и символы подчёркивания' }
    )
    public name: string;
    constructor(data?: Partial<TaxonomyBaseFieldRestoreRequest>) {
        this.name = typeof data?.name === 'string'
            ? (data.name.startsWith('f_') ? data.name : 'f_' + data.name)
            : data?.name;
    }
}
