export class Reference {
    public uuid: string;
    public name: string;
    public description: string;
    public isSystem: boolean;

    constructor(data?: Partial<Reference>) {
        this.uuid = data?.uuid || null;
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.isSystem = data?.isSystem || false;
    }
}
