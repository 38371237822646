/**
 * Main order status used in DB
 */
export enum OrderStatus {
    STATUS_NEW = 1,
    STATUS_IN_WORK = 2,
    STATUS_CLOSED = 10,
    STATUS_SHIPPED = 11,
}

/**
 * Order status in text form for more convenient usage
 */
export type OrderStatusTxt = 'new' | 'work' | 'closed' | 'shipped';

/**
 * @deprecated Use OrderStatusTxt
 */
export type TOrderStatus = OrderStatusTxt | 'all';

const orderStatusMap: Record<OrderStatusTxt, OrderStatus> = {
    new: OrderStatus.STATUS_NEW,
    work: OrderStatus.STATUS_IN_WORK,
    closed: OrderStatus.STATUS_CLOSED,
    shipped: OrderStatus.STATUS_SHIPPED,
};

export function orderStatusFrom(value: OrderStatusTxt): OrderStatus {
    return orderStatusMap[value];
}

export function orderStatusListFrom(list: OrderStatusTxt[]): OrderStatus[] {
    return Array.isArray(list)
        ? list.flatMap(value => (orderStatusMap[value] ? orderStatusMap[value] : []))
        : [];
}
