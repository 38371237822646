import { RestApiResponse } from '../../common/RestApiResponse';
import { ReferenceItem } from '../ReferenceItem';

export class TaxonomyReferenceItemGetResponse extends RestApiResponse {
    public item: ReferenceItem;

    constructor(data?: Partial<TaxonomyReferenceItemGetResponse>) {
        super(data);
        this.item = data?.item;
    }
}
