import { IsString, Length } from 'class-validator';

export class AssemblyLocation {
    @IsString()
    @Length(1, 3)
    public label: string;

    @IsString()
    @Length(1, 255)
    public address: string;

    constructor(data?: Partial<AssemblyLocation>) {
        this.address = data?.address;
        this.label = data?.label;
    }

    public static listFrom(value: string): AssemblyLocation[] {
        let dataList: Partial<AssemblyLocation>[];
        try {
            dataList = JSON.parse(value);
        } catch {
            return undefined;
        }

        if (!Array.isArray(dataList)) {
            throw new Error(`Expected serialised array of "AssemblyLocation", but received: ${value}`);
        }

        return dataList.map(d => new AssemblyLocation(d));
    }

    public static listTo(list: AssemblyLocation[]): string {
        return JSON.stringify(list);
    }
}
