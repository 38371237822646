import { IsNumber } from 'class-validator';

export class ProfileContractorSourceAvailabilityRequest {
    @IsNumber()
    public contractorId: number;

    constructor(data?: Partial<ProfileContractorSourceAvailabilityRequest>) {
        this.contractorId = data && data.contractorId;
    }
}

