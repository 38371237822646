import { Observable, Subject } from 'rxjs';

import { TProfileEdgeCrease } from './TProfileEdgeCrease';
import { EProfilePaintSide } from './EProfilePaintSide';
import { IProfileLine } from './IProfileLine';

export class Profile {
    private readonly profileChanges = new Subject<void>();

    public readonly changes$: Observable<void> = this.profileChanges.asObservable();
    public lines: IProfileLine[] = [];
    public edgeCreaseSizeMM = 15;
    public creaseLeft: TProfileEdgeCrease = 0;
    public creaseRight: TProfileEdgeCrease = 0;
    public paintSide: EProfilePaintSide = 1;

    constructor(profileData?: string) {
        this.load(profileData);
    }

    private static recalculateStartLineAngle(angle: number, direction: number, rotateAngle: number): { angle: number; direction: number } {
        angle += rotateAngle;
        if (Math.abs(angle) > 360) {
            angle %= 360;
        }
        if (angle > 180) {
            angle -= 360;
            direction = -1;
        }
        if (angle > 0 && angle <= 180) {
            direction = 1;
        }
        if (angle > -180 && angle <= 0) {
            direction = -1;
        }
        if (angle <= -180) {
            angle += 360;
            direction = 1;
        }
        return { angle, direction };
    }

    private static parseEdgeCrease(value: string): TProfileEdgeCrease {
        switch (value) {
            case '-1':
                return -1;
            case '0':
                return 0;
            case '1':
                return 1;
            default:
                return 0;
        }
    }

    public propagateChanges(): void {
        this.profileChanges.next();
    }

    public clear(propagateChanges = true): void {
        this.lines.length = 0;
        this.edgeCreaseSizeMM = this.edgeCreaseSizeMM || 15;
        this.creaseLeft = 0;
        this.creaseRight = 0;
        this.paintSide = 1;
        if (propagateChanges) {
            this.propagateChanges();
        }
    }

    public load(profileData: string): void {
        this.clear(false);
        if (!profileData) {
            this.propagateChanges();
            return;
        }
        const data = profileData.split('%');
        this.paintSide = parseInt(data[1], 10) || EProfilePaintSide.noPaint;
        this.creaseLeft = Profile.parseEdgeCrease(data[4]) || this.creaseLeft;
        this.edgeCreaseSizeMM = parseInt(data[5], 10) || this.edgeCreaseSizeMM;
        this.creaseRight = Profile.parseEdgeCrease(data[6]) || this.creaseRight;
        const parsedLines = data[8] && data[8].split('|') || [];
        const reg = /1;(\d+);(\d);(\d+);(\d+);0;0/;
        parsedLines.forEach(lineData => {
            if (reg.test(lineData)) {
                const parts = reg.exec(lineData);
                const angle = parseInt(parts[1], 10);
                const direction = 2 * parseInt(parts[2], 10) - 1;
                this.lines.push({
                    length: parseInt(parts[3], 10),
                    length2: parseInt(parts[4], 10),
                    alpha: angle * direction,
                    direction: direction,
                });
            }
        });
        this.propagateChanges();
    }

    public rotate(angleDegree: number): void {
        if (this.lines.length) {
            const firstLine = this.lines[0];
            const startLineAngle = Profile.recalculateStartLineAngle(firstLine.alpha, firstLine.direction, angleDegree);
            firstLine.alpha = startLineAngle.angle;
            firstLine.direction = startLineAngle.direction;
            this.propagateChanges();
        }
    }

    public toString(): string {
        if (!this.lines.length) {
            return null;
        }
        const lines = this.lines.map((line, index) => {
            let alpha = line.alpha;
            let direction = line.direction;
            if (!index) {
                const startLineAngle = Profile.recalculateStartLineAngle(line.alpha, line.direction, 0);
                alpha = startLineAngle.angle;
                direction = startLineAngle.direction;
            }
            return [1, Math.abs(alpha), (direction + 1) / 2, line.length, line.length2, 0, 0].join(';');
        });
        return [
            -1, this.paintSide, -1, 0,
            this.creaseLeft, this.edgeCreaseSizeMM,
            this.creaseRight, this.edgeCreaseSizeMM,
            lines.join('|'),
            this.lengthTotal(),
        ].join('%');
    }

    public lengthTotal(): number {
        let len = this.lines.reduce((sum: number, line: IProfileLine) => sum + Math.max(line.length, line.length2), 0);
        len += this.creaseLeft === 0 ? 0 : this.edgeCreaseSizeMM;
        len += this.creaseRight === 0 ? 0 : this.edgeCreaseSizeMM;
        return len;
    }

    /**
     * It's necessary for drawing in a coordinate system with an inverted Y-axis.
     * Essentially, Canvas Y-axis has an opposite direction with the 3D axis.
     */
    public inverse(): void {
        this.creaseLeft *= this.creaseLeft !== 0 ? -1 : 0;
        this.creaseRight *= this.creaseRight !== 0 ? -1 : 0;
        this.lines.forEach(line => {
            line.alpha *= -1;
            line.direction *= -1;
        });
        if (this.paintSide === EProfilePaintSide.paintTop) {
            this.paintSide = EProfilePaintSide.paintBottom;
        } else if (this.paintSide === EProfilePaintSide.paintBottom) {
            this.paintSide = EProfilePaintSide.paintTop;
        }
        this.propagateChanges();
    }
}
