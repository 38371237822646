import { IsOptional, IsString } from 'class-validator';

import { IOrderExternalData } from './IOrderExternalData';

export class OrderExternalData implements IOrderExternalData {
    @IsOptional()
    @IsString()
    public barCodeInWork: string;

    @IsOptional()
    @IsString()
    public barCodeClosed: string;

    constructor(data?: IOrderExternalData) {
        if (data) {
            this.barCodeInWork = data.barCodeInWork;
            this.barCodeClosed = data.barCodeClosed;
        }
    }
}
