import { IsNotEmpty, IsString } from 'class-validator';

export class TaxonomyReferenceItemRestoreRequest {
    @IsString()
    @IsNotEmpty()
    public uuid: string;

    constructor(data?: Partial<TaxonomyReferenceItemRestoreRequest>) {
        this.uuid = data && data.uuid;
    }
}
