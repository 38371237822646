import { IsString } from 'class-validator';

export class RoleGetRequest {
    @IsString()
    public roleName: string;

    constructor(data?: Partial<RoleGetRequest>) {
        this.roleName = data && data.roleName;
    }
}
