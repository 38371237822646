import { IsString, MinLength } from 'class-validator';

export class TaxonomyBaseFieldDeleteRequest {
    @IsString()
    @MinLength(1)
    public name: string;

    constructor(data?: Partial<TaxonomyBaseFieldDeleteRequest>) {
        this.name = data?.name;
    }
}
