import { Observable } from 'rxjs';

import { AbstractControlOptions, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

/**
 * @deprecated use FormControl from Angular13
 */
export class CustomFormControl<T> extends UntypedFormControl {
    public readonly value: T;
    public readonly valueChanges: Observable<T>;

    constructor(
        formState?: T, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(formState, validatorOrOpts, asyncValidator);
    }

    public setValue(value: T, options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        emitModelToViewChange?: boolean;
        emitViewToModelChange?: boolean;
    }): void {
        super.setValue(value, options);
    }

    public patchValue(value: T, options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        emitModelToViewChange?: boolean;
        emitViewToModelChange?: boolean;
    }): void {
        super.patchValue(value, options);
    }

    public reset(formState?: T, options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
    }): void {
        super.reset(formState, options);
    }

    ////////// CUSTOM //////////
    public setEnabled(isEnabled: boolean, opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
    }): void {
        if (isEnabled) {
            super.enable(opts);
        } else {
            super.disable(opts);
        }
    }
}

/**
 * @deprecated use FormGroup from Angular13
 */
export class CustomFormGroup<T> extends UntypedFormGroup {
    public readonly value: T;
    public readonly valueChanges: Observable<T>;
    public controls: { [key in keyof T]: CustomFormControl<T[key]> };

    constructor(
        controls: { [key in keyof T]: CustomFormControl<T[key]> },
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    public get<R extends keyof T>(path: R): CustomFormControl<T[R]> | null {
        return super.get(path as string) as CustomFormControl<T[R]>;
    }

    public setValue(value: T, options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        emitModelToViewChange?: boolean;
        emitViewToModelChange?: boolean;
    }): void {
        super.setValue(value, options);
    }

    public patchValue(value: T, options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        emitModelToViewChange?: boolean;
        emitViewToModelChange?: boolean;
    }): void {
        super.patchValue(value, options);
    }

    public reset(formState?: T, options?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
    }): void {
        super.reset(formState, options);
    }
}
