import { IsBoolean } from 'class-validator';

import { RestApiResponse } from '../common/RestApiResponse';

export class ProfileContractorSourceAvailabilityResponse extends RestApiResponse {
    @IsBoolean()
    public contractorSourceAvailable: boolean;

    constructor(data?: Partial<ProfileContractorSourceAvailabilityResponse>) {
        super(data);
        this.contractorSourceAvailable = data && data.contractorSourceAvailable;
    }
}
