import { IsEnum, IsInt, IsNotEmpty, IsOptional, Min, ValidateIf } from 'class-validator';

import { FieldOptionsImageProportion } from './FieldOptionsImageProportion';

/**
 * @see backend-php/modules/rest_common/models/taxonomy/FieldImage.php
 */
export class FieldOptionsImage {
    @IsOptional()
    @IsInt()
    @Min(1)
    public width: number;

    @IsOptional()
    @IsInt()
    @Min(1)
    public height: number;

    @ValidateIf((o: FieldOptionsImage) => !!o.proportion || (!!o.width && !!o.height))
    @IsNotEmpty({ message: 'Укажите вид ограничения - "точное соответствие" или "не более, чем"' })
    @IsEnum(FieldOptionsImageProportion, { message: 'Неправильное значение пропорций' })
    public proportion: FieldOptionsImageProportion;

    constructor(data?: Partial<FieldOptionsImage>) {
        this.width = data?.width;
        this.height = data?.height;
        this.proportion = data?.proportion;
    }
}
