import { IsInt, Min } from 'class-validator';

/**
 * @see backend-php/modules/rest_common/models/taxonomy/FieldNumber.php
 */
export class FieldOptionsNumber {
    @IsInt({ message: 'Количество знаков после запятой должно быть целым положительным числом' })
    @Min(0, { message: 'Количество знаков после запятой должно быть целым положительным числом' })
    public accuracy: number;

    constructor(data?: Partial<FieldOptionsNumber>) {
        this.accuracy = data?.accuracy || 0;
    }
}
