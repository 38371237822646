import { IsInt } from 'class-validator';

export class NomenclatureTypeChildGetRequest {
    @IsInt()
    public id: number;

    constructor(data?: Partial<NomenclatureTypeChildGetRequest>) {
        this.id = data?.id;
    }
}
