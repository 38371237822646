import { IsBoolean, IsIn, IsOptional, IsString } from 'class-validator';

import { CrudItemListRequest } from '../../crud';
import { NomenclatureTypeFieldType } from '../enum/NomenclatureTypeFieldType';

export class NomenclatureTypeFieldGetListRequest extends CrudItemListRequest {
    @IsString()
    public nomTypeUuid: string;

    @IsOptional()
    @IsIn([NomenclatureTypeFieldType.characteristic, NomenclatureTypeFieldType.parameter, NomenclatureTypeFieldType.group])
    public type?: NomenclatureTypeFieldType;

    @IsOptional()
    @IsBoolean()
    public extendProperties?: boolean;

    constructor(data?: Partial<NomenclatureTypeFieldGetListRequest>) {
        super(data);
        this.nomTypeUuid = data && data.nomTypeUuid;
        this.type = data && data.type;
        this.extendProperties = data && data.extendProperties;
    }
}
