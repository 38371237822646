/**
 * Access permissions
 * The values are duplicated from \lisa10_rest_common\helpers\rbac\PermissionHelper
 */
export enum PermissionName {

    /** Франшизер. Доступ к экспериментальным функциям */
    F_EXPERIMENTAL_FUNCTIONS = 'F_EXPERIMENTAL_FUNCTIONS',

    /////////////// RBAC ///////////////
    /** Франшизер. Просмотр удаленных объектов */
    F_VIEW_DELETED_OBJECTS = 'F_VIEW_DELETED_OBJECTS',

    /** Франшизер. Редактирование контактных лиц */
    F_EDIT_PERSON = 'F_EDIT_PERSON',

    /** Франшизер. Редактирование паролей пользователей */
    F_EDIT_USER_PASSWORDS = 'F_EDIT_USER_PASSWORDS',

    /** Франшизер. Редактирование ролей */
    F_EDIT_ROLES = 'F_EDIT_ROLES',

    /** Франшизер. Login by permanent authenticate token */
    F_ALLOW_AUTHENTICATE_BY_PERMANENT_TOKEN = 'F_ALLOW_AUTHENTICATE_BY_PERMANENT_TOKEN',

    /** Дилер. Login by permanent authenticate token */
    D_ALLOW_AUTHENTICATE_BY_PERMANENT_TOKEN = 'D_ALLOW_AUTHENTICATE_BY_PERMANENT_TOKEN',

    /////////////// TAXONOMY ///////////////
    /** Франшизер. Редактирование справочников и их структуры. */
    F_TAXONOMY_EDIT_TAXONOMY = 'F_TAXONOMY_EDIT_TAXONOMY',

    /** Франшизер. Редактирование списка элементов справочников */
    F_TAXONOMY_EDIT_TAXONOMY_TERM = 'F_TAXONOMY_EDIT_TAXONOMY_TERM',

    /////////////// ORDER ///////////////
    /** Франшизер. Просмотр и редактирование заказов */
    F_EDIT_ORDER = 'F_EDIT_ORDER',

    /** Дилер. Просмотр и редактирование своих заказов */
    D_EDIT_OWN_ORDER = 'D_EDIT_OWN_ORDER',

    /** Дилер. Просмотр и редактирование любых заказов дилера */
    D_EDIT_ANY_ORDER = 'D_EDIT_ANY_ORDER',

    /** Франшизер. Печать этикеток у исполненного заказа */
    F_PRINT_FINALIZED_ORDER = 'F_PRINT_FINALIZED_ORDER',

    /** Франшизер. Перевод заказа в работу. */
    F_ORDER_TO_WORK = 'F_ORDER_TO_WORK',

    /** Дилер. Перевод заказа в работу. */
    D_ORDER_TO_WORK = 'D_ORDER_TO_WORK',

    /** Франшизер. Перевод/возврат заказа в новые. */
    F_ORDER_TO_NEW = 'F_ORDER_TO_NEW',

    /** Франшизер. Просмотр и редактирование заказов в работе. */
    F_EDIT_IN_WORK_ORDER = 'F_EDIT_IN_WORK_ORDER',

    /** Дилер. Перевод/возврат заказа в новые. */
    D_ORDER_TO_NEW = 'D_ORDER_TO_NEW',

    /** Франшизер. Управление скидкой на заказ. */
    F_EDIT_ORDER_DISCOUNT = 'F_EDIT_ORDER_DISCOUNT',

    /** Франшизер. Экспорт заказов */
    F_EXPORT_ORDER_DATA = 'F_EXPORT_ORDER_DATA',

    /////////////// COMPANY ///////////////
    /** Франшизер. Редактирование списка контрагентов */
    F_EDIT_CONTRAGENT = 'F_EDIT_CONTRAGENT',

    /** Франшизер. Редактирование дилера */
    F_EDIT_DEALER = 'F_EDIT_DEALER',

    /** Дилер. Просмотр всех контрагентов франшизера (Даже назначенных другим дилерам!) */
    D_VIEW_FRANCHISER_CONTRAGENTS = 'D_VIEW_FRANCHISER_CONTRAGENTS',

    /** Франшизер. Редактирование настроек */
    F_EDIT_SETTINGS = 'F_EDIT_SETTINGS',

    /////////////// NOMENCLATURE ///////////////
    /** Франшизер. Редактирование номенклатуры */
    F_NOMENCLATURE_EDIT = 'F_NOMENCLATURE_EDIT',

    /** Франшизер. Просмотр каталога */
    F_VIEW_CATALOG = 'F_VIEW_CATALOG',

    /** Дилер. Просмотр общего каталога */
    D_VIEW_CATALOG = 'D_VIEW_CATALOG',

    /** Франшизер. Редактирование типов номенклатуры */
    F_NOMENCLATURE_EDIT_TYPE = 'F_NOMENCLATURE_EDIT_TYPE',

    /////////////// PRODUCTION ///////////////
    /** Франшизер. Работа на производственных участках */
    F_PRODUCTION = 'F_PRODUCTION',

    /** Франшизер. Редактирование участков */
    F_EDIT_AREA = 'F_EDIT_AREA',

    /** Настройка наличия материалов на участке */
    F_EDIT_AREA_MATERIALS = 'F_EDIT_AREA_MATERIALS',

    /////////////// ANNOUNCEMENT ///////////////
    F_ANNOUNCEMENT_UPDATE = 'F_ANNOUNCEMENT_UPDATE',
}
