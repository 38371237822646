import { IsInt, IsNotEmpty } from 'class-validator';

import { AbstractPersonRequest } from './AbstractPersonRequest';

export class PersonUpdateRequest extends AbstractPersonRequest {
    @IsInt()
    @IsNotEmpty()
    public id: number;

    constructor(data?: Partial<PersonUpdateRequest>) {
        super(data);
        this.id = data?.id;
    }
}
