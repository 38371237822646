import { IsInt, IsOptional } from 'class-validator';

export class OrderGetRequest {
    @IsInt()
    public orderId: number;

    @IsOptional()
    @IsInt()
    public areaId?: number;

    constructor(data?: Partial<OrderGetRequest>) {
        this.orderId = data?.orderId;
        this.areaId = data?.areaId;
    }
}
