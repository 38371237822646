import { IsString } from 'class-validator';

export class UserSetInfoRequest {
    @IsString()
    public firstName: string;

    @IsString()
    public lastName: string;

    @IsString()
    public middleName: string;

    @IsString()
    public phone: string;

    constructor(data?: Partial<UserSetInfoRequest>) {
        this.firstName = data && data.firstName;
        this.lastName = data && data.lastName;
        this.middleName = data && data.middleName;
        this.phone = data && data.phone;
    }
}
