import { RestApiResponse } from '../../common/RestApiResponse';
import { NomenclaturePosition } from '../NomenclaturePosition';

export class NomenclaturePositionGetResponse extends RestApiResponse {
    public item: NomenclaturePosition;

    constructor(data?: Partial<NomenclaturePositionGetResponse>) {
        super(data);
        this.item = new NomenclaturePosition(data?.item);
    }
}
