import { IsBoolean, IsOptional } from 'class-validator';

export class AnnouncementGetListRequest {
    @IsOptional()
    @IsBoolean()
    public unacceptedOnly: boolean;

    constructor(data?: Partial<AnnouncementGetListRequest>) {
        this.unacceptedOnly = data?.unacceptedOnly;
    }
}
