import { NomenclaturePosition } from './NomenclaturePosition';
import { PriceSource } from './enum/PriceSource';

export class Price {
    public sourceValue = PriceSource.PRICE_BASE_VALUE;
    public priceValue = 0;

    constructor(public priceTypeUuid: string, public nomPositionUuid: string) {
    }

    public static from(data?: Partial<Price>): Price {
        const price = new Price(data?.priceTypeUuid, data?.nomPositionUuid);
        price.sourceValue = data?.sourceValue || PriceSource.PRICE_BASE_VALUE;
        price.priceValue = data?.priceValue || 0;
        return price;
    }

    public static getValue(nomPosition: NomenclaturePosition, price: Price): number {
        if (price) {
            switch (price.sourceValue) {
                case PriceSource.PRICE_VALUE:
                    return price.priceValue;
                case PriceSource.PRICE_BASE_VALUE:
                    return nomPosition.values.f_price;
            }
        }
        return nomPosition.values.f_price;
    }
}
