import { IsNotEmpty, IsString } from 'class-validator';

export class ReferenceCopyRequest {
    @IsString()
    @IsNotEmpty()
    public uuid: string;

    constructor(data?: Partial<ReferenceCopyRequest>) {
        this.uuid = data?.uuid;
    }
}
