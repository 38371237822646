import { IsOptional, IsString } from 'class-validator';

export class ContactGetListRequest {
    @IsString()
    @IsOptional()
    public readonly contactPhone?: string;

    @IsString()
    @IsOptional()
    public readonly contactPerson?: string;

    constructor(data?: Partial<ContactGetListRequest>) {
        this.contactPerson = data?.contactPerson || '';
        this.contactPhone = data?.contactPhone || '';
    }
}
