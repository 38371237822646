import { Injectable } from '@angular/core';

import { Cache } from '../cache/cache';
import { CacheMemoryStorage } from '../cache/cache-memory-storage';
import { CacheOptions } from '../cache/cache-options';

@Injectable()
export class CustomCacheService {
    private cache = new Cache(new CacheMemoryStorage());

    public exists(key: string): boolean {
        return this.cache.exists(key);
    }

    public get<T>(key: string): T {
        return this.cache.get(key);
    }

    public set<T>(key: string, value: T, options?: CacheOptions): boolean {
        return this.cache.set(key, value, options);
    }

    public removeAll(): void {
        this.cache.removeAll();
    }

    public removeTag(tag: string): void {
        this.cache.removeTag(tag);
    }
}
