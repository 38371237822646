/**
 * Created by optimistex on 23.06.17.
 */

export interface IMenuItem {
    label: string;
    state?: string;
    weight?: number;
    permissions?: string | string[];
    roles?: string | string[];
    items?: IMenu;
    svgIcon?: string;   // only for root items
    canShow?: () => boolean;
}

export interface IMenu {
    [itemId: string]: IMenuItem;
}

export interface IMenuRawData {
    [userGroup: string]: IMenu;
}

export interface IMenuItemIndexed extends IMenuItem {
    id: string;
    isActive: boolean;
}

export class CMenuData {
    public items: IMenuItemIndexed[] = [];
    public itemsByLevel: IMenuItemIndexed[] = [];
    public isActive = false;
}
