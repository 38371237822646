import { IsBoolean, IsOptional } from 'class-validator';

/**
 * @see backend-php/modules/rest_common/models/taxonomy/FieldProfile.php
 */
export class FieldOptionsProfile {
    @IsOptional()
    @IsBoolean({ message: 'Должно быть boolean' })
    public printUnwrapProfileSticker: boolean;

    constructor(data?: Partial<FieldOptionsProfile>) {
        this.printUnwrapProfileSticker = data?.printUnwrapProfileSticker;
    }
}
